import { createStore } from 'vuex'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import $socket from '../lib/socketIo'

import router from '@/router/index'

// import { Plugins } from '@capacitor/core'
// const { PushNotifications } = Plugins
// import udpController from '../lib/udpController'
// import tcpController from '../lib/tcpController'

export default createStore({
  state () {
    return {
      platform: [],
      keyboardStatus: 'closed',
      token: localStorage.getItem('access_token') || null,
      userId: localStorage.getItem('user') || null,
      propertyId: localStorage.getItem('property_id') || null,
      agentId: localStorage.getItem('agent_id') || null,
      userName: localStorage.getItem('user_name') || null,
      userRole: localStorage.getItem('user_role') || null,
      landlordId: localStorage.getItem('landlord_id') || null,
      language: localStorage.getItem('language') || null,
      isMember: null,
      userData: [],
      profileImage: '',
      propertyScenes: [],
      userProperties: [],
      propertyRooms: [],
      propertyDevices: [],
      landlordBuildings: [],
      landlordProperties: [],
      landlordDevices: [],
      landlordOverviewPieData: [],
      statusBucketLandlord: [],
      landlordNotifications: [],
      showLandlordsNotification: false,
      latestLandlordsNotification: [],
      deviceAddStatus: null,
      propertyAutomation: [],
      notification: [],
      propertyAlarm: false,
      weather: {},
      gatewayProgress: false,
      userHasNoGateway: null,
      data: [],
      tcpData: null,
      socketTcpId: null,
      tcpStatus: true,
      gatewayIp: null,
      availableWifi: [],
      gwSessionId: null,
      communication: false,
      loadingUserData: false,
      pullEshopData: false,
      initLocation: {},
      eshopCart: JSON.parse(localStorage.getItem('basket')) || [],
      landlordEvents: []
    }
  },
  // MUTATIONS ===========================================================================================================================================
  mutations: {
    platform (state, platform) {
      state.platform = platform
    },
    initLocation (state, { lat, lng }) {
      state.initLocation = { lat, lng }
    },
    geofenceAdded (state, { geofenceEnabled, geofenceData }) {
      state.userData[0].geofenceEnabled = geofenceEnabled
      state.userData[0].geofenceData = geofenceData
    },
    removeGeofence (state, { geofenceEnabled, geofenceData }) {
      state.userData[0].geofenceEnabled = geofenceEnabled
      state.userData[0].geofenceData = geofenceData
    },
    keyboardEvent (state, event) {
      state.keyboardStatus = event
    },
    retrievedToken (state, {
      token,
      userId,
      propertyId,
      agentId,
      userName,
      userRole,
      landlordId,
      language
    }) {
      state.token = token
      state.userId = userId
      state.propertyId = propertyId
      state.agentId = agentId
      state.userName = userName
      state.userRole = userRole
      state.landlordId = landlordId
      state.language = language
    },
    destroyToken (state) {
      localStorage.clear()
      state.token = null
      state.userId = null
      state.propertyId = null
      state.agentId = null
      state.userName = null
      state.userRole = null
      state.landlordId = null
      state.language = null
      state.userData = []
      state.profileImage = ''
      state.propertyScenes = []
      state.userProperties = []
      state.propertyRooms = []
      state.propertyDevices = []
      state.landlordBuildings = []
      state.landlordProperties = []
      state.landlordDevices = []
      state.statusBucketLandlord = []
      state.deviceAddStatus = null
      state.propertyAutomation = []
      state.notification = []
      state.propertyAlarm = false
      state.weather = {}
      state.gatewayProgress = false
      state.data = []
      state.tcpData = null
      state.socketTcpId = null
      state.tcpStatus = true
      state.availableWifi = []
      state.gwSessionId = null
      state.communication = false
      state.loadingUserData = false
      localStorage.clear()
      $socket.close()
    },
    // Notifications
    SOCKET_NOTIFICATION (state, message) {
      state.notification.unshift(message)
    },
    // SOCKET_LANDLORDNOTIFICATION (state, message) {
    //   state.landlordNotifications.unshift(message)
    // },
    removeNotificationById (state, id) {
      const notificationIndex = state.notification.findIndex(item => item.id === id)
      state.notification.splice(notificationIndex, 1)
    },
    removeLandlordNotificationById (state, id) {
      const notificationIndex = state.landlordNotifications.findIndex(item => item.id === id)
      state.landlordNotifications.splice(notificationIndex, 1)
    },
    removeAllNotifications (state) {
      if (state.userRole === 'Landlord') {
        state.landlordNotifications = []
      } else {
        state.notification = []
      }
    },
    notificationSetting (state, { setting, id }) {
      const deviceIndex = state.propertyDevices.findIndex(item => item.id === id)
      state.propertyDevices[deviceIndex].notification = setting
    },
    notificationSettingWhenScene (state, { value, deviceId }) {
      const deviceIndex = state.propertyDevices.findIndex(item => item.id === deviceId)
      state.propertyDevices[deviceIndex].notificationWhenScene = value
    },
    allNotificationsRead (state) {
      setTimeout(() => {
        state.notification.forEach(item => {
          item.read = true
        })
      }, 1000)
    },
    controlPushNotificationWhenAlarm (state, { setting }) {
      state.userProperties[0].pushNotificationWhenAlarm = setting
    },
    controlPushWhenSceneChange (state, { setting }) {
      state.userProperties[0].pushWhenSceneChange = setting
    },
    controlPushWhenAlarmChange (state, { setting }) {
      state.userProperties[0].pushWhenAlarmChange = setting
    },
    // User Data
    startLoadingUserData (state) {
      state.loadingUserData = true
    },
    userData (state, { user, userProperties, propertyDevices, propertyNotifications, weather, profileImage }) {
      state.userData = user
      state.profileImage = profileImage
      state.userProperties = userProperties
      state.isMember = userProperties[0].isMember
      state.propertyDevices = propertyDevices
      state.notification = propertyNotifications
      state.weather = weather
      if (state.userData.length) {
        state.loadingUserData = false
      }
    },
    profileImage (state, profileImage) {
      state.profileImage = profileImage
    },
    updateUserProfile (state, data) {
      state.userData[0].name = data.name
      state.userData[0].email = data.email
      state.userData[0].phone = data.phone
    },
    changeAppLanguage (state, value) {
      state.language = value
    },
    weatherUpdate (state, weather) {
      state.weather = weather
    },
    alarmStatus (state, data) {
      state.propertyAlarm = data.alarm
    },
    propertyScenes (state, data) {
      state.propertyScenes = data
    },
    editSceneName (state, { sceneName, sceneId }) {
      const sceneIndex = state.propertyScenes.findIndex(item => item.id === sceneId)
      state.propertyScenes[sceneIndex].sceneName = sceneName
    },
    editScenePicture (state, { scenePicture, sceneId }) {
      const sceneIndex = state.propertyScenes.findIndex(item => item.id === sceneId)
      state.propertyScenes[sceneIndex].scenePicture = scenePicture
    },
    updateSceneDeviceOnOff (state, data) {
      const sceneIndex = state.propertyScenes.findIndex(item => item.id === data.sceneId)
      const sceneDeviceIndex = state.propertyScenes[sceneIndex].sceneSettings.devices.findIndex(item => item.ieee === data.ieee)
      state.propertyScenes[sceneIndex].sceneSettings.devices[sceneDeviceIndex].switchgear = data.newValue
    },
    addDeviceToScene (state, data) {
      const sceneIndex = state.propertyScenes.findIndex(item => item.id === data.sceneId)
      state.propertyScenes[sceneIndex].sceneSettings.devices.push(data.device)
    },
    removeDeviceFromScene (state, data) {
      const sceneIndex = state.propertyScenes.findIndex(item => item.id === data.sceneId)
      const sceneDeviceIndex = state.propertyScenes[sceneIndex].sceneSettings.devices.findIndex(item => item.ieee === data.ieee)
      state.propertyScenes[sceneIndex].sceneSettings.devices.splice(sceneDeviceIndex, 1)
    },
    turnOnOffSceneSecurity (state, { sceneSetting, sceneSecurityObject, sceneId }) {
      const sceneIndex = state.propertyScenes.findIndex(item => item.id === sceneId)
      state.propertyScenes[sceneIndex].alarm = sceneSetting
      state.propertyScenes[sceneIndex].sceneSettings.security = sceneSecurityObject
    },
    updateSecurityInScene (state, { sceneSecurityObject, sceneId }) {
      const sceneIndex = state.propertyScenes.findIndex(item => item.id === sceneId)
      state.propertyScenes[sceneIndex].sceneSettings.security = sceneSecurityObject
    },
    deleteScene (state, scene) {
      const sceneIndex = state.propertyScenes.findIndex(item => item.id === scene.id)
      state.propertyScenes.splice(sceneIndex, 1)
    },
    // rooms =---
    propertyRooms (state, data) {
      state.propertyRooms = data
    },
    editRoom (state, data) {
      const roomIndex = state.propertyRooms.findIndex(item => item.id === data.roomId)
      state.propertyRooms[roomIndex].roomName = data.roomName
      state.propertyRooms[roomIndex].roomClass = data.roomClass
    },
    deleteRoom (state, id) {
      const roomIndex = state.propertyRooms.findIndex(item => item.id === id)
      state.propertyRooms.splice(roomIndex, 1)
    },
    // Automation --------------------------------------------------------------------------------------------------------------------------------
    // getPropertyAutomation (state, data) {
    //   state.propertyAutomation = data[0].automation
    // },
    // addPropertyAutomation (state, data) {
    //   state.propertyAutomation.push(data)
    // },
    // updateAutomationActiveInactive (state, data) {
    //   let active
    //   if (data.active === 'true') {
    //     active = true
    //   } else {
    //     active = false
    //   }
    //   const automationIndex = state.propertyAutomation.findIndex(item => item.automationName === data.automation.automationName)
    //   state.propertyAutomation[automationIndex].active = active
    // },
    // removePropertyAutomation (state, data) {
    //   const automationIndex = state.propertyAutomation.findIndex(item => item.automationName === data.automationName)
    //   state.propertyAutomation.splice(automationIndex, 1)
    //   console.log(state.propertyAutomation)
    // },
    // TCP UDP Comm ----------------------------------------------------------------------------------------------------------------------------------
    tcpData (state, data) {
      state.tcpData = data
    },
    socketTcpId (state, data) {
      state.socketTcpId = data
    },
    tcpStatus (state, data) {
      state.tcpStatus = data
    },
    clearTcpData (state, message) {
      state.tcpData = null
    },
    availableWifi (state, data) {
      state.availableWifi = data
    },
    gwSessionId (state, data) {
      state.gwSessionId = data
    },
    UDPResponse (state, data) {
      state.gatewayIp = data.remoteAddress
    },
    // Gateway and devices -------------------------------------------------------------------------------------------------------------------------
    userHasNoGateway (state, status) {
      state.userHasNoGateway = status
    },
    gatewayProgress (state, status) {
      state.gatewayProgress = status
    },
    gatewayAddDevice (state, data) {
      state.propertyDevices.push(data)
    },
    resetAddingDevice (state) {
      state.deviceAddStatus = null
    },
    updateLight (state, { type, value, ieee }) {
      const deviceIndex = state.propertyDevices.findIndex(item => item.ieee === ieee)
      if (type === 'lightLevel') {
        state.propertyDevices[deviceIndex].lightLevel = value
      }
      if (type === 'colorTemp') {
        state.propertyDevices[deviceIndex].colorTemp = value
      }
      if (type === 'switchgear') {
        state.propertyDevices[deviceIndex].switchgear = value
      }
    },
    updateSwitch (state, { type, value, ieee }) {
      const deviceIndex = state.propertyDevices.findIndex(item => item.ieee === ieee)
      if (type === 'switchgear') {
        state.propertyDevices[deviceIndex].switchgear = value
      }
    },
    devicesRefresh (state, devices) {
      state.propertyDevices = devices.data
    },
    updateDeviceSetting (state, deviceSetting) {
      const deviceIndex = state.propertyDevices.findIndex(item => item.ieee === deviceSetting.ieee)
      state.propertyDevices[deviceIndex].deviceName = deviceSetting.deviceName
      state.propertyDevices[deviceIndex].propertyRoomId = deviceSetting.deviceRoom
    },
    removeDevice (state, deviceId) {
      const deviceIndex = state.propertyDevices.findIndex(item => item.id === deviceId)
      state.propertyDevices.splice(deviceIndex, 1)
    },
    communication (state, value) {
      state.communication = value
    },
    // Landlords
    createBuilding (state, response) {
      state.landlordBuildings.push(response.data)
    },
    editBuilding (state, data) {
      const index = state.landlordBuildings.findIndex(item => item.id === data.buildingId)
      if (index !== -1) {
        state.landlordBuildings[index].buildingAddress = data.buildingAddress
        state.landlordBuildings[index].buildingCity = data.buildingCity
        state.landlordBuildings[index].buildingFlatsPerFloor = data.buildingFlatsPerFloor
        state.landlordBuildings[index].buildingFloors = data.buildingFloors
        state.landlordBuildings[index].buildingName = data.buildingName
        state.landlordBuildings[index].buildingPostCode = data.buildingPostCode
      }
    },
    getLandlordBuildingsAndProperties (state, response) {
      // console.log(response)
      const result = {
        tenantsInPortfolio: response.data.tenantsInPortfolio,
        landlordDevicesInactive: response.data.landlordDevicesInactive,
        landlordDevicesLength: response.data.landlordDevicesLength,
        landlordPropertiesGatewayInstalled: response.data.landlordPropertiesGatewayInstalled,
        landlordPropertiesGatewayInstalledButNotActive: response.data.landlordPropertiesGatewayInstalledButNotActive,
        landlordPropertiesLength: response.data.landlordPropertiesLength,
        landlordDevicesInactivePropertyIds: response.data.landlordDevicesInactivePropertyIds
      }

      const fireEvents = response.data.fireEvents
      const leakEvents = response.data.leakEvents

      const todayMinus7Days = Date.now() - (3600000 * 24 * 7)
      const todayMinus31days = Date.now() - (3600000 * 24 * 31)

      const last7DaysFireEvent = []
      const last31DaysFireEvent = []

      const last7daysLeakEvent = []
      const last31daysLeakEvent = []

      fireEvents.forEach(item => {
        if (item.startTime > todayMinus7Days) {
          last7DaysFireEvent.push(item)
        }
        if (item.startTime > todayMinus31days) {
          last31DaysFireEvent.push(item)
        }
      })

      leakEvents.forEach(item => {
        if (item.startTime > todayMinus7Days) {
          last7daysLeakEvent.push(item)
        }
        if (item.startTime > todayMinus31days) {
          last31daysLeakEvent.push(item)
        }
      })
      state.landlordEvents = {
        last7DaysFireEvent: last7DaysFireEvent,
        last31DaysFireEvent: last31DaysFireEvent,
        last7daysLeakEvent: last7daysLeakEvent,
        last31daysLeakEvent: last31daysLeakEvent
      }

      state.landlordOverviewPieData = result

      state.landlordBuildings = response.data.landlordBuildings
      state.landlordProperties = response.data.landlordProperties
      state.landlordDevices = response.data.landlordDevices
      state.userData = response.data.userData
      // console.log(response.data.notifications)
      state.landlordNotifications = response.data.notifications
    },
    landlordCreateApartment (state, response) {
      state.landlordProperties.push(response.data)
    },
    landlordEditApartment (state, data) {
      const index = state.landlordProperties.findIndex(item => item.id === data.propertyId)
      if (index !== -1) {
        state.propertyFloor = data.propertyFloor
        state.propertyName = data.propertyName
        state.propertyType = data.propertyType
        state.flatNumber = data.flatNumber
      }
    },
    landlordApartmentProgress (state, id) {
      console.log(id)
    },
    gatewayStatus (state, value) {
      state.userProperties[0].gatewayActive = value
    },
    notificationDelaysLandlord (state, { value, type }) {
      if (type === 'waterLeakNotificationDelay') {
        state.userData[0].waterLeakNotificationDelay = value
      } else if (type === 'fireNotificationDelay') {
        state.userData[0].fireNotificationDelay = value
      }
    },
    settingVariables (state, { value, type }) {
      if (type === 'climateWarningBelow') {
        state.userData[0].climateWarningBelow = value
      } else if (type === 'climateWarningAbove') {
        state.userData[0].climateWarningAbove = value
      }
    },
    addItemToCart (state, { item, paymentStyle }) {
      const index = state.eshopCart.findIndex(i => i.id === item.id && i.paymentStyle === paymentStyle)
      if (index === -1) {
        const itemToAdd = {
          id: item.id,
          price: item.itemPrice,
          itemCategory: item.itemCategory,
          subPrice: item.subscriptionPriceMonth,
          subscriptionUpfrontPrice: item.subscriptionUpfrontPrice,
          picture: item.itemPicturesAWSLinks[0],
          name: item.itemName,
          sku: item.itemSKU,
          paymentStyle,
          qty: 1,
          productId: item.productId,
          productPriceId: item.productPriceId,
          productPriceSubId: item.productPriceSubId,
          productSubUpfrontPriceId: item.productSubUpfrontPriceId,
          memberSubscriptionPriceMonth: item.memberSubscriptionPriceMonth,
          memberSubscriptionPriceId: item.memberSubscriptionPriceId
        }
        state.eshopCart.push(itemToAdd)
      } else {
        state.eshopCart[index].qty++
      }
      localStorage.setItem('basket', JSON.stringify(state.eshopCart))
    },
    removeItemFromCart (state, { item, paymentStyle }) {
      const index = state.eshopCart.findIndex(i => i.id === item.id && i.paymentStyle === paymentStyle)
      if (index !== -1) {
        if (state.eshopCart[index].qty > 1) {
          state.eshopCart[index].qty--
        } else {
          state.eshopCart.splice(index, 1)
        }
      }
      localStorage.setItem('basket', JSON.stringify(state.eshopCart))
    },
    removeSpecificAmountFromBasket (state, { item, paymentStyle, availableQty }) {
      const index = state.eshopCart.findIndex(i => i.id === item.id && i.paymentStyle === paymentStyle)
      if (index !== -1) {
        if (availableQty === 0) {
          state.eshopCart.splice(index, 1)
        } else {
          state.eshopCart[index].qty = availableQty
        }
      }
      localStorage.setItem('basket', JSON.stringify(state.eshopCart))
    },
    clearBasket (state) {
      state.eshopCart = []
      localStorage.removeItem('basket')
    }
  },
  // GETTERS ===========================================================================================================================================
  getters: {
    loggedIn: state => state.token !== null
  },
  // ACTIONS ===========================================================================================================================================
  actions: {
    getAPIVersion () {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/version/`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    newNotification () {
      const ding = new Audio(require('../assets/sounds/ding.mp3'))
      ding.muted = false
      ding.play()
    },
    // ESHOP
    pullEshopData ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/eshop/items`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getStripeIntents ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/eshop/get-intents`, data)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updatePaymentMethodIntent ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/app-users/update-payment-method-intent/`, { propertyId: this.state.propertyId }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    checkPaymentStatus ({ commit }, { sessionId, type }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/eshop/check-payment`, { sessionId, type })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    createOrder ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/eshop/create-order`, data)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getMyOrdersAndSubs ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/app-users/my-orders-and-subscriptions/${this.state.propertyId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    checkDiscountCoupon ({ commit }, { val, cart }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/eshop/check-discount-coupon/${val}`, cart)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getShippingMethods ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/eshop/get-shipping-methods`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    cancelSubscription ({ commit }, subData) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/app-users/cancel-subscription/`, subData, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    requestEarlyOrderReturn ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/app-users/early-cancel-subscription/`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getCrossSaleData ({ commit }, { type }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/eshop/get-cross-sell-up-sell-data/${type}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getItemStockQTYs ({ commit }, { itemSKU }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/eshop/check-stock-qty-for-item/${itemSKU}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getAllItemsStockQTY ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/eshop/check-stock-qty/`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getAvailableBudbeeBoxes ({ commit }, { postCode }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/eshop/get-budbee-boxes/${postCode}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // =======
    geofenceAdded ({ commit }, { id, lat, lng, radius }) {
      const geofenceEnabled = true
      const geofenceData = {
        id,
        lat,
        lng,
        radius
      }
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/app-users/geofence/${this.state.userId}`, { geofenceEnabled, geofenceData }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('geofenceAdded', { geofenceEnabled, geofenceData })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    geofenceEvent ({ commit }, fences) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/app-users/geofence/events/${this.state.userId}`, fences, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    removeGeofence ({ commit }) {
      const geofenceEnabled = false
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/app-users/geofence/${this.state.userId}`, { geofenceEnabled, geofenceData: {} }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('removeGeofence', { geofenceEnabled, geofenceData: {} })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getHelpCenterContent ({ commit }) {
      const locale = this.state.language
      let localeActive = ''
      if (locale === 'EN') {
        localeActive = 'en'
      } else if (locale === 'SE') {
        localeActive = 'sv'
      } else {
        localeActive = 'en'
      }
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_STRAPI_API}/help-centers?_locale=${localeActive}`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getDevicesHelpContent ({ commit }) {
      const locale = this.state.language
      let localeActive = ''
      if (locale === 'EN') {
        localeActive = 'en'
      } else if (locale === 'SE') {
        localeActive = 'sv'
      } else {
        localeActive = 'en'
      }
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_STRAPI_API}/device-helps?_locale=${localeActive}`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getLandlordsData ({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/landlords/${token}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    registerLandlord ({ commit }, { data, token }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/landlords/${token}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    /// / App user  -------------------------------------------------------------------------------------------------------------------------
    sendEmailVerification ({ commit }, { email, name }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/register/send-verification-email/`, { email, name })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    verifyMyEmail ({ commit }, { code }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/register/verify-email/${code}`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    SignUpNewUser ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/register`, data)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    completeRegistrationGetUsersData ({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/users/${token}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    completeRegistrationRegisterUser ({ commit }, { data, token }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/users/${token}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    inviteNewHouseMember ({ commit }, newUserData) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/app-users/invite-house-member/${this.state.propertyId}`, newUserData, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    saveEditedHouseMember ({ commit }, newUserData) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/app-users/edit-house-member/`, newUserData, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getHouseMembers ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/app-users/get-house-members/${this.state.propertyId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    removeHouseMember ({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/user/app-users/delete-house-member/${id}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    removeMyAccount ({ commit }, { id, role }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/app-users/delete-my-account/`, { id, role }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    LoginUser ({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/login`, credentials)
          .then((response) => {
            const token = response.data
            const decodedToken = jwtDecode(token)
            const userId = decodedToken.payload.user
            const landlordId = decodedToken.payload.landlordId
            const userName = decodedToken.payload.userName
            const userRole = decodedToken.payload.userRole
            const propertyId = decodedToken.payload.property
            const agentId = decodedToken.payload.agentId
            const language = decodedToken.payload.language
            localStorage.setItem('access_token', token)
            localStorage.setItem('user', userId)
            localStorage.setItem('property_id', propertyId)
            localStorage.setItem('landlord_id', landlordId)
            localStorage.setItem('agent_id', agentId)
            localStorage.setItem('user_name', userName)
            localStorage.setItem('user_role', userRole)
            localStorage.setItem('language', language)
            commit('retrievedToken', {
              token,
              userId,
              propertyId,
              agentId,
              userName,
              userRole,
              landlordId,
              language
            })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    OAuthSingUpCheckCredentials ({ commit }, { name, email }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/oauth`, { name, email })
          .then((response) => {
            console.log(response)
            if (response.data.type === 'Success') {
              const token = response.data.token
              const decodedToken = jwtDecode(token)
              const userId = decodedToken.payload.user
              const landlordId = decodedToken.payload.landlordId
              const userName = decodedToken.payload.userName
              const userRole = decodedToken.payload.userRole
              const propertyId = decodedToken.payload.property
              const agentId = decodedToken.payload.agentId
              const language = decodedToken.payload.language
              localStorage.setItem('access_token', token)
              localStorage.setItem('user', userId)
              localStorage.setItem('property_id', propertyId)
              localStorage.setItem('landlord_id', landlordId)
              localStorage.setItem('agent_id', agentId)
              localStorage.setItem('user_name', userName)
              localStorage.setItem('user_role', userRole)
              localStorage.setItem('language', language)
              commit('retrievedToken', {
                token,
                userId,
                propertyId,
                agentId,
                userName,
                userRole,
                landlordId,
                language
              })
            }
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    newProfileImage ({ commit }, { profileImage }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/app-users/update-profile-image/${this.state.userId}`, { profileImage: profileImage }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('profileImage', profileImage)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    LogOut ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/app-users/logout/${this.state.userId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('destroyToken')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
      // this.removeNotificationToken()
    },
    updateUserProfile ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/user/app-users/update-profile/${this.state.userId}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('updateUserProfile', data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateUserPassword ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/user/app-users/update-password/${this.state.userId}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getUserData ({ commit }) {
      commit('startLoadingUserData')
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/user-data/${this.state.userId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            const user = response.data[0]
            // console.log(user)
            if (!user.length) {
              this.dispatch('LogOut').then(() => {
                router.replace('/login')
              })
            } else {
              if (!user[0].active) {
                this.dispatch('LogOut').then(() => {
                  router.replace('/login')
                })
              } else {
                const profileImage = user[0].profileImage
                const userProperties = response.data[1]
                const propertyScenes = response.data[2]
                const propertyRooms = response.data[3]
                const propertyDevices = response.data[4]
                const propertyNotifications = response.data[5]
                const weather = response.data[6]
                commit('userData', { user, userProperties, propertyDevices, propertyNotifications, weather, profileImage })
                commit('propertyScenes', propertyScenes)
                commit('propertyRooms', propertyRooms)
                resolve(response)
              }
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    changeAppLanguage ({ commit }, { language }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/app-users/change-language/${this.state.userId}`, { language }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('changeAppLanguage', language)
            localStorage.setItem('language', language)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    resetMyPassword ({ commit }, { email }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/forgot-password/reset`, { email })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    completeResetPasswordGetData ({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/public/forgot-password/check/${token}`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    completeResetPassword ({ commit }, { token, password }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/public/forgot-password/new/${token}`, { password })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    submitFeedback ({ commit }, { feedback }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/app-users/feedback/${this.state.userId}`, { feedback }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    restartGateway ({ state, commit }) {
      console.log(state.token)
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/user-data/restart-gateway/${this.state.propertyId}`, {
          headers: {
            Authorization: 'Bearer ' + state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // Alarm --------
    getAlarmStatus ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/property-security/${this.state.propertyId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('alarmStatus', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    setAlarmStatus ({ commit }, alarmSetting) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/user/property-security/${this.state.propertyId}`, { alarmSetting: alarmSetting, userId: this.state.userId }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('alarmStatus', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // TCP UDP Comm (to be refactored )  -------------------------------------------------------------------------------------------------------------------------
    socketTcpId ({ commit }, data) {
      commit('socketTcpId', data)
    },
    tcpData ({ commit }, data) {
      commit('tcpData', data)
    },
    tcpStatus ({ commit }, message) {
      commit('tcpStatus', message.status)
    },
    clearTcpData ({ commit }, message) {
      commit('clearTcpData', message)
    },
    availableWifi ({ commit }, data) {
      commit('availableWifi', data)
    },
    gwSessionId ({ commit }, data) {
      commit('gwSessionId', data)
    },
    /// / Property socket IO----------------------------------------------------------------------- -----------------------------------------------------------
    // if socket.io crashes here we do the handling (timeout should be changed to several attempts to connect)
    socket_disconnect ({ state, getters, commit }) {
      $socket.disconnect()
      if (state.token !== null) {
        if (getters.loggedIn) {
          console.log('reconnecting')
          $socket.query = { token: state.token }
          $socket.io.opts.query = { token: state.token }
          setTimeout(() => {
            $socket.open()
          }, 1000)
        } else {
          console.log('loggedout')
        }
      } else {
        console.log('logged out - not reconnecting')
      }
    },
    // unused but we have option to send messages over the socket.io too
    sendMessageIo ({ commit }, data) {
      $socket.emit('message', data)
    },
    // initial connection to socket.io server when app loaded
    connectSocketIo ({ state, commit }) {
      if (state.token !== null) {
        if (!$socket.connected) {
          console.log('Socket not connected')
          setTimeout(() => {
            $socket.query = { token: state.token }
            $socket.io.opts.query = { token: state.token }
            $socket.open()
          }, 500)
          setTimeout(() => {
            console.log('Socket connected', $socket.connected)
          }, 1500)
        } else {
          console.log('already connected')
        }
      } else {
        console.log('logged out')
      }
    },
    socket_landlordnotification ({ state, dispatch }, message) {
      state.landlordNotifications.unshift(message)
      state.latestLandlordsNotification = message
      dispatch('newNotification')
      state.showLandlordsNotification = true
      setTimeout(() => { state.showLandlordsNotification = false }, 100)
    },
    // gateway data updates 'DEVICES' coming via socket.io
    socket_dataUpdate ({ dispatch, commit, state }, message) {
      console.log(message)
      if (message.type === 'gwAuthOk') {
        commit('gatewayProgress', false)
      } else if (message.type === 'pullPropertyDevices') {
        dispatch('getPropertyDevices')
        commit('gatewayProgress', false)
      } else if (message.type === 'pullPropertyScenes') {
        dispatch('getPropertyScenes')
      } else if (message.type === 'deviceUpdate') {
        const devices = state.propertyDevices
        for (let i = 0; i < devices.length; i++) {
          if (devices[i].ieee === null && devices[i].zigbeeModel === message.zigbeeModel) {
            state.propertyDevices[i].ieee = message.ieee
            this.state.deviceAddStatus = 'success'
          }
        }

        const deviceIndex = state.propertyDevices.findIndex(item => item.ieee === message.ieee)
        if (deviceIndex === -1) {
          dispatch('getPropertyDevices')
          commit('gatewayProgress', false)
        } else {
          state.propertyDevices[deviceIndex].active = message.active
          state.propertyDevices[deviceIndex].ep = message.ep
          commit('gatewayProgress', false)
        }
      } else if (message.type === 'statusUpdate') {
        const deviceIndex = this.state.propertyDevices.findIndex(item => item.ieee === message.ieee)
        if (deviceIndex !== -1) {
          this.state.propertyDevices[deviceIndex].status = message.status
        } else {
          dispatch('getPropertyDevices')
        }
      } else if (message.type === 'humiUpdate') {
        const deviceIndex = this.state.propertyDevices.findIndex(item => item.ieee === message.ieee)
        if (deviceIndex !== -1) {
          this.state.propertyDevices[deviceIndex].humi = message.humi
        }
      } else if (message.type === 'tempUpdate') {
        const deviceIndex = this.state.propertyDevices.findIndex(item => item.ieee === message.ieee)
        if (deviceIndex !== -1) {
          this.state.propertyDevices[deviceIndex].temp = message.temp
        }
      } else if (message.type === 'colorTemp') {
        const deviceIndex = this.state.propertyDevices.findIndex(item => item.ieee === message.ieee)
        if (deviceIndex !== -1) {
          this.state.propertyDevices[deviceIndex].colorTemp = message.colorTemp
        }
      } else if (message.type === 'switchgear') {
        const deviceIndex = this.state.propertyDevices.findIndex(item => item.ieee === message.ieee)
        if (deviceIndex !== -1) {
          this.state.propertyDevices[deviceIndex].switchgear = message.switchgear
        }
      } else if (message.type === 'lightLevel') {
        const deviceIndex = this.state.propertyDevices.findIndex(item => item.ieee === message.ieee)
        if (deviceIndex !== -1) {
          this.state.propertyDevices[deviceIndex].lightLevel = message.lightLevel
        }
      } else if (message.type === 'weatherUpdate') {
        commit('weatherUpdate', message)
      } else if (message.type === 'deviceAddError') {
        this.state.deviceAddStatus = 'error'
      } else if (message.type === 'deviceAddSuccess') {
        this.state.deviceAddStatus = 'success'
      } else if (message.type === 'landlordDeviceAddError') {
        this.state.statusBucketLandlord.push(message)
      } else if (message.type === 'landlordDeviceAddSuccess') {
        this.state.statusBucketLandlord.push(message)
      } else if (message.type === 'landlordFireDetected') {
        const propertyIndex = this.state.landlordProperties.findIndex(item => item.id === message.propertyId)
        this.state.landlordProperties[propertyIndex].fire = true
        this.state.landlordProperties[propertyIndex].fireDetectedAt = message.fireDetectedAt
      } else if (message.type === 'landlordFireOk') {
        const propertyIndex = this.state.landlordProperties.findIndex(item => item.id === message.propertyId)
        this.state.landlordProperties[propertyIndex].fire = false
      } else if (message.type === 'landlordWaterLeakDetected') {
        const propertyIndex = this.state.landlordProperties.findIndex(item => item.id === message.propertyId)
        this.state.landlordProperties[propertyIndex].waterLeak = true
        this.state.landlordProperties[propertyIndex].leakDetectedAt = message.leakDetectedAt
      } else if (message.type === 'landlordWaterLeakOk') {
        const propertyIndex = this.state.landlordProperties.findIndex(item => item.id === message.propertyId)
        this.state.landlordProperties[propertyIndex].waterLeak = false
      } else if (message.type === 'gwConnected') {
        commit('gatewayStatus', true)
      } else if (message.type === 'gwDisconnected') {
        commit('gatewayStatus', false)
      } else if (message.type === 'energyUpdate') {
        const deviceIndex = this.state.propertyDevices.findIndex(item => item.ieee === message.ieee)
        if (deviceIndex !== -1) {
          this.state.propertyDevices[deviceIndex].currentConsumption = message.currentConsumption
          this.state.propertyDevices[deviceIndex].totalConsumption = message.totalConsumption
        }
      } else if (message.type === 'colorUpdate') {
        const deviceIndex = this.state.propertyDevices.findIndex(item => item.ieee === message.ieee)
        if (deviceIndex !== -1) {
          this.state.propertyDevices[deviceIndex].colorY = message.colorY
          this.state.propertyDevices[deviceIndex].colorX = message.colorX
        }
      } else if (message.type === 'pullEshopData') {
        state.pullEshopData = true
        setTimeout(() => {
          state.pullEshopData = false
        }, 1000)
      }
    },
    // Notifications  ------------------------------------------------------------------------------------------------------------------------- ---------------------------------------------------------
    notificationToken ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/notifications/${this.state.propertyId}`, { notificationToken: data, appUserId: this.state.userId, role: this.state.userRole }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    removeNotification ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/user/notifications/${id}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('removeNotificationById', id)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    removeLandlordNotification ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/user/notifications/${id}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('removeLandlordNotificationById', id)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    notificationSetting ({ commit }, { setting, id }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/notifications/setting/${id}`, { setting }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('notificationSetting', { setting, id })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    notificationWhenScene ({ commit }, { value, deviceId }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/notifications/setting-when-scene/${deviceId}`, { value }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('notificationSettingWhenScene', { value, deviceId })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    removeAllNotification ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/user/notifications/remove-all/${this.state.propertyId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('removeAllNotifications')
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    allNotificationsRead ({ commit }) {
      commit('allNotificationsRead')
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/user/notifications/${this.state.propertyId}`, { dummy: 'dummy' }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    controlPushNotificationWhenAlarm ({ commit }, { setting }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/notifications/setting-push-when-alarm/${this.state.propertyId}`, { setting }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('controlPushNotificationWhenAlarm', { setting })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    controlPushWhenSceneChange ({ commit }, { setting }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/notifications/setting-when-scene-change/${this.state.propertyId}`, { setting }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('controlPushWhenSceneChange', { setting })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    controlPushWhenAlarmChange ({ commit }, { setting }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/notifications/setting-when-alarm-change/${this.state.propertyId}`, { setting }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('controlPushWhenAlarmChange', { setting })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    notificationDelaysLandlord ({ commit }, { value, type }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/properties/notification-delays/${this.state.landlordId}`, { value, type }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('notificationDelaysLandlord', { value, type })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    /// / Property gateway and devices ------------------------------------------------------------------------------------------------------------------------- ---------------------------------------------------------
    addGateway ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const gateway = { gateway: data }
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/gateway/${this.state.propertyId}`, gateway, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('gatewayProgress', true)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    wasGatewayAdded ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/gateway/was-added/${this.state.propertyId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addPropertyDevice ({ commit }, device) {
      console.log('adddonf')
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-devices/add-device/${this.state.propertyId}`, device, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('gatewayAddDevice', device)
            commit('gatewayProgress', true)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    resetAddingDeviceStatus ({ commit }) {
      commit('resetAddingDevice')
    },
    getPropertyDevices ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/property-devices/${this.state.propertyId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('devicesRefresh', response)
            commit('gatewayProgress', false)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    lightControl ({ commit }, { type, value, ieee, ep }) {
      if (this.state.communication === true) {
        return
      }
      commit('communication', true)
      const data = {
        type,
        value,
        ieee,
        ep
      }
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-devices/light-control/${this.state.propertyId}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('updateLight', { type, value, ieee })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    switchControl ({ commit }, { type, value, ieee, ep }) {
      const data = {
        type,
        value,
        ieee,
        ep
      }
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-devices/switch-control/${this.state.propertyId}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('updateSwitch', { type, value, ieee })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateDeviceSetting ({ commit }, deviceSetting) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-devices/device-setting/${this.state.propertyId}`, deviceSetting, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('updateDeviceSetting', deviceSetting)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getDeviceHistory ({ commit }, { ieee, type }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-devices/device-history/${ieee}`, { type }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    removeDevice ({ commit }, { ieee, ep, deviceId }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-devices/remove-device/${this.state.propertyId}`, { ieee: ieee, ep: ep, deviceId: deviceId }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('removeDevice', deviceId)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    identifyDevice ({ commit }, { deviceId }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/property-devices/identify-device/${deviceId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    /// / Property scenes ------------------------------------------------------------------------------------------------------------------------- ---------------------------------------------------------
    setPropertyScene ({ commit }, sceneId) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/user/property-scenes/${this.state.propertyId}`, { sceneId: sceneId, userId: this.state.userId }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            const newPropertyScenes = response.data
            commit('propertyScenes', newPropertyScenes)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getPropertyScenes ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/property-scenes/${this.state.propertyId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            const propertyScenes = response.data
            commit('propertyScenes', propertyScenes)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addPropertyScene ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-scenes/${this.state.propertyId}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            const newPropertyScenes = response.data
            commit('propertyScenes', newPropertyScenes)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    editSceneName ({ commit }, { sceneName, sceneId }) {
      console.log(sceneName)
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/user/property-scenes/change-scene-name/${sceneId}`, { newSceneName: sceneName }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('editSceneName', { sceneName, sceneId })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    editScenePicture ({ commit }, { scenePicture, sceneId }) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/user/property-scenes/change-scene-picture/${sceneId}`, { newScenePicture: scenePicture }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('editScenePicture', { scenePicture, sceneId })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateDevicesOnOffValueScenes ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-scenes/update-device-on-off/${this.state.propertyId}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('updateSceneDeviceOnOff', data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addDeviceToScene ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-scenes/add-device-to-scene/${this.state.propertyId}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('addDeviceToScene', data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    removeDeviceFromScene ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-scenes/remove-device-from-scene/${this.state.propertyId}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('removeDeviceFromScene', data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    turnOnOffSceneSecurity ({ commit }, { sceneSetting, sceneSecurityObject, sceneId }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-scenes/scene-security/${sceneId}`, { securitySetting: sceneSetting, sceneSecurityObject }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('turnOnOffSceneSecurity', { sceneSetting, sceneSecurityObject, sceneId })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateSecurityInScene ({ commit }, { sceneSecurityObject, sceneId }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-scenes/update-security/${sceneId}`, { sceneSecurityObject }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('updateSecurityInScene', { sceneSecurityObject, sceneId })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteScene ({ commit }, scene) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-scenes/delete-scene/${this.state.propertyId}`, scene, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('deleteScene', scene)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    /// / Automation ------------------------------------------------------------------------------------------------------------------------- ---------------------------------------------------------
    getPropertyAutomation ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/user/property-automation/${this.state.propertyId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            // commit('getPropertyAutomation', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addPropertyAutomation ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-automation/${this.state.propertyId}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            // commit('addPropertyAutomation', data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateAutomationActiveInactive ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-automation/update-active-inactive/${this.state.propertyId}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    editAutomation ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/user/property-automation/${this.state.propertyId}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    removePropertyAutomation ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-automation/remove-automation/${this.state.propertyId}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    /// / Property rooms ------------------------------------------------------------------------------------------------------------------------- ---------------------------------------------------------
    addPropertyRoom ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-rooms/${this.state.propertyId}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            const newPropertyRooms = response.data
            commit('propertyRooms', newPropertyRooms)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    editRoom ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/user/property-rooms/${this.state.propertyId}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('editRoom', data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteRoom ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/user/property-rooms/${id}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('deleteRoom', id)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    /// Landlords ================================================================================================================================================================
    createBuilding ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/buildings/${this.state.userId}`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('createBuilding', response)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    landlordSaveEditedBuilding ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/landlord/buildings/`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('editBuilding', data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    landlordSaveBuildingSpecificSettings ({ commit }, { buildingId, targetTemperature }) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/landlord/buildings/building-specific-settings/`, { buildingId, targetTemperature }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getLandlordBuildingsAndProperties ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/landlord/landlords/${this.state.userId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('getLandlordBuildingsAndProperties', response)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    landlordCreateApartment ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/properties/`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('landlordCreateApartment', response)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    landlordSaveEditedProperty ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/landlord/properties/`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('landlordEditApartment', data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getApartmentDetail ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/landlord/properties/${id}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getTenantsForApartment ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/landlord/properties/tenants/${id}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    inviteTenantToApartment ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/properties/tenants/`, data, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getDevicesSharedToTenant ({ commit }, { tenantId }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/landlord/properties/get-shared-tenant-devices/${tenantId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    saveEditedTenant ({ commit }, { tenantId, tenantEmail, tenantName, sharedDevices }) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/landlord/properties/save-edited-tenant/${tenantId}`, { tenantEmail, tenantName, sharedDevices }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    removeAllTenantsFromProperty ({ commit }, { apartmentId }) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/landlord/properties/remove-tenants/${apartmentId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addLandlordGateway ({ commit }, { newGateway, apartmentId }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/properties/gateway/${apartmentId}`, { gateway: newGateway }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    landlordRestartGatewayInApartment ({ commit }, apartment) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/landlord/properties/gateway/reset/${apartment.id}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    landlordAddPropertyDevice ({ commit }, { device, apartmentId }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/properties/devices/${apartmentId}`, device, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('landlordApartmentProgress', apartmentId)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    landlordRemoveDevice ({ commit }, { ieee, ep, deviceId, propertyId }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/user/property-devices/remove-device/${propertyId}`, { ieee: ieee, ep: ep, deviceId: deviceId }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('removeDevice', deviceId)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getChartDataForProperty ({ commit }, { id, dateRange, selectedFlat, selectedFloor, buildingCity }) {
      // console.log(id, dateRange)
      // console.log(typeof selectedFlat)
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/properties/get-data-for-charts/${id}`, { dateRange, selectedFlat, selectedFloor, buildingCity }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getDisconnectionDetail ({ commit }, buildingId) {
      console.log(buildingId)
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/landlord/properties/get-disconnection-detail-for-device/${buildingId}`, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    settingVariables ({ commit }, { value, type }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/landlord/properties/setting-variables/${this.state.landlordId}`, { value, type }, {
          headers: {
            Authorization: 'Bearer ' + this.state.token
          }
        })
          .then((response) => {
            commit('settingVariables', { value, type })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  modules: {}
})
