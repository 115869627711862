<template>
  <div class="background--modal" />
  <ion-page>
    <ion-header
      class="ion-no-border"
    >
      <ion-toolbar
        color="transparent"
      >
        <ion-item
          slot="start"
          lines="none"
          fill="clear"
          color="transparent"
          size="large"
          class="close-modal-button"
          @click="dismissModal()"
        >
          <img src="../assets/images/arrow-go-back-line.svg">
        </ion-item>
        <ion-title class="text--white text-18">
          {{ $t('Your Feedback') }}
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content
      v-if="selectedView === '1'"
      class="ion-padding"
    >
      <form class="form--dark text-left">
        <div class="feedback-form rounded-corners">
          <ion-textarea
            v-model="feedback"
            class="text--white form-inner"
            cols="10"
            rows="10"
            :auto-grow="true"
            :placeholder="$t('textFieldFeedbackPlaceholder')"
          />
        </div>
      </form>
      <div class="mt-30">
        <ion-button @click="submitFeedback">
          {{ $t('Submit Feedback') }}
        </ion-button>
      </div>
    </ion-content>
    <ion-content
      v-else
      class="ion-padding"
    >
      <div style="margin-top: 25vh">
        <span class="text-64 text--white"><i
          class="mdi mdi-check-circle"
          style="color: lightgreen;"
        /></span>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {} from 'ionicons/icons'

export default {
  name: 'Feedback',
  props: {
    close: { type: Function, default: null }
  },
  data () {
    return {
      feedback: '',
      selectedView: '1'
    }
  },
  methods: {
    submitFeedback () {
      console.log(this.feedback)
      this.$store.dispatch('submitFeedback', { feedback: this.feedback }).then(resp => {
        this.selectedView = '2'
        setTimeout(() => {
          this.dismissModal()
        }, 1500)
      })
    },
    dismissModal () {
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/theme/_backgrounds";
/* .available {
  color: green;
}
.not-available {
  color: red;
} */
.feedback-form {
  @include bg-box-room-device;
}
.form-inner {
    padding-left: 10px;
    padding-right: 10px;
}
.text-64 {
  font-size: 36px;
}
</style>