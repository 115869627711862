<template>
  <div class="background--modal" />
  <ion-header class="ion-no-border edit-profile-modal">
    <ion-toolbar color="transparent">
      <ion-button
        v-if="selectedView === '1'"
        slot="start"
        style="max-width: 60px;"
        fill="clear"
        color="transparent"
        size="large"
        @click="dismissModal()"
      >
        <img src="../../assets/images/arrow-go-back-line.svg">
      </ion-button>
      <ion-button
        v-else
        slot="start"
        style="max-width: 60px;"
        fill="clear"
        color="transparent"
        size="large"
        @click="selectedView = '1'; returnToPrevious()"
      >
        <img src="../../assets/images/arrow-go-back-line.svg">
      </ion-button>
      <ion-title
        v-if="selectedView === '1'"
        class="text--white text-18"
      >
        {{ $t('My home members') }}
      </ion-title>
      <ion-title
        v-else
        class="text--white text-18"
      >
        {{ $t('Add home members') }}
      </ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <div v-if="selectedView === '1'">
      <div v-if="!myHomeMembers.length">
        {{ $t('No members found in your home') }}
      </div>
      <div v-else>
        <ion-item
          v-for="member in myHomeMembers"
          :key="member"
          lines="none"
          class="h-65 rounded-corners mt-8"
        >
          <ion-label>
            <span class="text--white">{{ member.name }}</span><br><span class="text--primary text-12">{{ member.active ? $t('User is Active') : $t('Invitation pending') }}</span>
          </ion-label>
          <i
            slot="end"
            tappable
            class="mdi mdi-pencil text--primary"
            style="font-size: 18px;"
            @click="editing = true; selectMemberToEdit(member)"
          />
          <i
            slot="end"
            tappable
            class="mdi mdi-trash-can-outline text--gray"
            style="font-size: 18px;"
            @click="removeAlertConfirm(member)"
          />
        </ion-item>
      </div>
      <ion-fab
        slot="fixed"
        vertical="bottom"
        horizontal="end"
      >
        <ion-fab-button @click="selectedView = '2'">
          <ion-icon
            :icon="addOutline"
            class="text--black"
          />
        </ion-fab-button>
      </ion-fab>
    </div>
    <div v-else>
      <form
        class="form--dark"
        :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
      >
        <ion-item lines="none">
          <ion-label position="floating">
            {{ $t('Member Name') }}
          </ion-label>
          <ion-input
            v-model="newHomeMember.userName"
            enterkeyhint="done"
            class="capitalize"
            @keydown.enter="closeKeyboard"
          />
        </ion-item>
        <ion-item lines="none">
          <ion-label position="floating">
            {{ $t('Enter Email') }}
          </ion-label>
          <ion-input
            v-model="newHomeMember.userEmail"
            enterkeyhint="done"
            @keydown.enter="closeKeyboard"
          />
        </ion-item>
      </form>
      <ion-item
        class="ion-no-padding"
        lines="none"
        color="transparent"
      >
        <ion-text
          slot="start"
          class="text--white text--uppercase text-14"
        >
          {{ $t('scenes') }}
        </ion-text>
        <ion-text
          class="text--bold text--primary text--uppercase text-14"
        >
          {{ newHomeMember.userCanChangeScenes ? $t('Can Change') : $t('Cannot Change') }}
        </ion-text>
        <ion-toggle
          slot="end"
          v-model="newHomeMember.userCanChangeScenes"
        />
      </ion-item>
      <ion-item
        class="ion-no-padding"
        lines="none"
        color="transparent"
      >
        <ion-text
          slot="start"
          class="text--white text--uppercase text-14"
        >
          {{ $t('scenes') }}
        </ion-text>
        <ion-text
          class="text--bold text--primary text--uppercase text-14"
        >
          {{ newHomeMember.userCanEditCreateScenes ? $t('Can Edit/ Create') : $t('Cannot Edit/ Create') }}
        </ion-text>
        <ion-toggle
          slot="end"
          v-model="newHomeMember.userCanEditCreateScenes"
        />
      </ion-item>
      <ion-item
        class="ion-no-padding"
        lines="none"
        color="transparent"
      >
        <ion-text
          slot="start"
          class="text--white text--uppercase text-14"
        >
          {{ $t('rooms') }}
        </ion-text>
        <ion-text
          class="text--bold text--primary text--uppercase text-14"
        >
          {{ newHomeMember.userCanEditCreateRooms ? $t('Can Edit/ Create') : $t('Cannot Edit/ Create') }}
        </ion-text>
        <ion-toggle
          slot="end"
          v-model="newHomeMember.userCanEditCreateRooms"
        />
      </ion-item>
      <ion-item
        class="ion-no-padding"
        lines="none"
        color="transparent"
      >
        <ion-text
          slot="start"
          class="text--white text--uppercase text-14"
        >
          {{ $t('devices') }}
        </ion-text>
        <ion-text
          class="text--bold text--primary text--uppercase text-14"
        >
          {{ newHomeMember.userHasFullAccessDevices ? $t('Full Access') : $t('Controlled Access') }}
        </ion-text>
        <ion-toggle
          slot="end"
          v-model="newHomeMember.userHasFullAccessDevices"
        />
      </ion-item>
      <div
        v-if="!newHomeMember.userHasFullAccessDevices"
        class="mt-10"
      >
        <div class="text--right text--gray text-12">
          <span>{{ $t('View') }}</span>
          <!-- <span> | {{ $t('Control') }} | </span> -->
          <span style="padding-right: 7px; padding-left: 10px;">|</span>
          <span
            class="mr-20"
          >{{ $t('Edit') }}</span>
        </div>
        <ion-item
          v-for="device in propertyDevices"
          :key="device"
          lines="none"
          class="h-65 rounded-corners mt-8 text--white"
        >
          <img
            slot="start"
            :src="require('../../assets/devices/' + device.zigbeeModel + '.png')"
          >
          <ion-label>
            {{ device.deviceName }} <br>
            <span class="text--gray text-14"> {{ deviceRoom(device.propertyRoomId) }}</span>
          </ion-label>
          <ion-toggle
            class="mr-10"
            :checked="viewDeviceExists(device.id) !== false"
            @ionChange="canView(device.id)"
          />
          <!-- <ion-checkbox
            :disabled="viewDeviceExists(device.id) !== true"
            class="mr-10"
            tappable
            :value="canControllExists(device.id) ? false : true "
            :checked="canControllExists(device.id) ? true : false"
            @ionChange="canControll(device.id)"
          /> -->
          <ion-checkbox
            :disabled="viewDeviceExists(device.id) !== true"
            tappable
            :value="canEditExists(device.id) ? false : true "
            :checked="canEditExists(device.id) ? true : false"
            @ionChange="canEdit(device.id)"
          />
        </ion-item>
      </div>
      <ion-button
        v-if="editing"
        class="mt-30 h-50"
        :disabled="inviting"
        @click="saveEditedHouseMember(); inviting = true;"
      >
        <ion-spinner
          v-if="inviting"
          name="crescent"
        />
        <span v-else>{{ $t('Save house member') }}</span>
      </ion-button>
      <ion-button
        v-else
        class="mt-30 h-50"
        :disabled="inviting || !newHomeMember.userEmail.length"
        @click="inviteNewMember(); inviting = true;"
      >
        <ion-spinner
          v-if="inviting"
          name="crescent"
        />
        <span v-else>{{ $t('Send invitation') }}</span>
      </ion-button>
    </div>
  </ion-content>
</template>

<script>
import { alertController } from '@ionic/vue'
import { closeOutline, addOutline, checkmarkDoneOutline, personCircleOutline } from 'ionicons/icons'
import { defineComponent } from 'vue'
import { Keyboard } from '@capacitor/keyboard'

export default defineComponent({
  name: 'Members',
  props: {
    close: { type: Function, default: null }
  },
  data () {
    return {
      closeOutline,
      checkmarkDoneOutline,
      personCircleOutline,
      addOutline,
      selectedView: '1',
      myHomeMembers: [],
      inviting: false,
      editing: false,
      newHomeMember: {
        userName: '',
        userEmail: '',
        userHasFullAccessDevices: true,
        userCanChangeScenes: true,
        userCanEditCreateScenes: true,
        userCanEditCreateRooms: true,
        userDevices: [],
        myAddress: this.$store.state.userData[0].myAddress
      }
    }
  },
  computed: {
    propertyDevices () {
      return this.$store.state.propertyDevices
    },
    platform () {
      return this.$store.state.platform
    }
  },
  created () {
    this.getMyHomeMembers()
  },
  methods: {
    getMyHomeMembers () {
      this.$store.dispatch('getHouseMembers').then(response => {
        this.myHomeMembers = response.data
      })
    },
    deviceRoom (id) {
      const roomId = Number(id)
      const rooms = this.$store.state.propertyRooms
      const found = rooms.find(element => element.id === roomId)
      return found ? found.roomName : '--'
    },
    dismissModal () {
      this.close()
    },
    canView (id) {
      const index = this.newHomeMember.userDevices.findIndex(item => item.id === id)
      if (index === -1) {
        const canView = {
          id,
          canView: true,
          canControll: false,
          canEdit: false
        }
        this.newHomeMember.userDevices.push(canView)
      } else {
        this.newHomeMember.userDevices[index].canView = false
        this.newHomeMember.userDevices[index].canControll = false
        this.newHomeMember.userDevices[index].canEdit = false
        this.newHomeMember.userDevices.splice(index, 1)
      }
    },
    viewDeviceExists (id) {
      let result
      const index = this.newHomeMember.userDevices.findIndex(item => item.id === id)
      if (index === -1) {
        result = false
      } else {
        result = true
      }
      return result
    },
    canControll (id) {
      const index = this.newHomeMember.userDevices.findIndex(item => item.id === id)
      if (index === -1) return
      if (this.newHomeMember.userDevices[index].canControll === true) {
        this.newHomeMember.userDevices[index].canControll = false
      } else {
        this.newHomeMember.userDevices[index].canControll = true
      }
    },
    canControllExists (id) {
      let result
      const index = this.newHomeMember.userDevices.findIndex(item => item.id === id)
      if (index === -1) {
        result = false
      } else {
        if (this.newHomeMember.userDevices[index].canControll) {
          result = true
        } else {
          result = false
        }
      }
      return result
    },
    canEdit (id) {
      const index = this.newHomeMember.userDevices.findIndex(item => item.id === id)
      if (index === -1) return
      if (this.newHomeMember.userDevices[index].canEdit) {
        this.newHomeMember.userDevices[index].canEdit = false
      } else {
        this.newHomeMember.userDevices[index].canEdit = true
      }
    },
    canEditExists (id) {
      let result
      const index = this.newHomeMember.userDevices.findIndex(item => item.id === id)
      if (index === -1) {
        result = false
      } else {
        if (this.newHomeMember.userDevices[index].canEdit) {
          result = true
        } else {
          result = false
        }
      }
      return result
    },
    inviteNewMember () {
      if (this.inviting) return
      this.newHomeMember.userName = this.newHomeMember.userName.replace(/(^\w|\s\w)/g, m => m.toUpperCase())
      this.$store.dispatch('inviteNewHouseMember', this.newHomeMember).then(response => {
        this.getMyHomeMembers()
        this.inviting = false
        this.returnToPrevious()
        this.selectedView = '1'
      })
    },
    selectMemberToEdit (member) {
      const memberToEdit = {
        userId: member.id,
        userName: member.name,
        userEmail: member.email,
        userHasFullAccessDevices: member.userHasFullAccessDevices,
        userCanChangeScenes: member.userCanChangeScenes,
        userCanEditCreateScenes: member.userCanEditCreateScenes,
        userCanEditCreateRooms: member.userCanEditCreateRooms,
        userDevices: member.userDevices
      }
      this.newHomeMember = memberToEdit
      this.selectedView = '2'
    },
    saveEditedHouseMember () {
      if (this.inviting) return
      this.$store.dispatch('saveEditedHouseMember', this.newHomeMember).then(response => {
        this.getMyHomeMembers()
        this.inviting = false
        this.returnToPrevious()
        this.selectedView = '1'
      })
    },
    returnToPrevious () {
      this.editing = false
      this.newHomeMember = {
        userName: '',
        userEmail: '',
        userHasFullAccessDevices: true,
        userCanChangeScenes: true,
        userCanEditCreateScenes: true,
        userCanEditCreateRooms: true,
        userDevices: [],
        myAddress: this.$store.state.userData[0].myAddress
      }
    },
    removeMember (member) {
      this.$store.dispatch('removeHouseMember', { id: member.id }).then(response => {
        this.getMyHomeMembers()
      })
    },
    async removeAlertConfirm (member) {
      const alert = await alertController
        .create({
          cssClass: 'remove-device',
          header: `${this.$t('Remove home member')} ${member.name}?`,
          // message: 'Are you sure you want to remove this Device?',
          buttons: [
            {
              text: this.$t('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: blah => {
              }
            },
            {
              text: this.$t('Remove'),
              role: 'destructive',
              handler: () => {
                this.removeMember(member)
              }
            }
          ]
        })
      return alert.present()
    },
    closeKeyboard () {
      Keyboard.hide()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import '~@/theme/_backgrounds';
.h-65 {
   --min-height: 65px;
   @include bg-box-item;
}
img {
  max-width: 40px;
}
.text--right {
  text-align: end;
}
</style>
