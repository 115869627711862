<template>
  <div :class="userRole === 'Landlord' ? 'background--modal-landlord' : 'background--modal'" />
  <ion-header class="ion-no-border">
    <ion-toolbar color="transparent">
      <ion-button
        v-if="selectedView === 1"
        slot="start"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="dismissModal()"
      >
        <img src="../assets/images/arrow-go-back-line.svg">
      </ion-button>
      <ion-button
        v-else-if="selectedView > 1 && selectedViewNotification === 1"
        slot="start"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="selectedView = 1"
      >
        <img src="../assets/images/arrow-go-back-line.svg">
      </ion-button>
      <ion-button
        v-else-if="selectedViewNotification === 2"
        slot="start"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="selectedViewNotification = 1, notificationToEdit = {}"
      >
        <img src="../assets/images/arrow-go-back-line.svg">
      </ion-button>
      <ion-title class="text--white text-18">
        {{ selectedViewName }}
      </ion-title>
      <!-- <ion-button
        v-if="selectedView > 1"
        slot="end"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
      >
        <ion-icon
          color="primary"
          size="large"
          :icon="checkmarkDoneOutline"
        />
      </ion-button> -->
    </ion-toolbar>
  </ion-header>
  <ion-content
    overflow-scroll="true"
    class="ion-padding-start ion-padding-end ion-padding-bottom"
  >
    <div v-if="selectedView === 1">
      <ion-item
        button
        detail="false"
        lines="none"
        class="h-65 rounded-corners"
        @click="selectedView = 6"
      >
        <i
          slot="start"
          class="mdi mdi-tune-variant text--gray"
          style="font-size: 22px;"
        />
        <ion-text
          slot="start"
          class="text--white text-16"
        >
          {{ $t('applicationSettings') }}
        </ion-text>
        <ion-icon
          slot="end"
          size="small"
          color="primary"
          :icon="chevronForwardOutline"
        />
      </ion-item>
      <ion-item
        button
        detail="false"
        lines="none"
        class="h-65 rounded-corners mt-8"
        @click="openFeedbackModal"
      >
        <i
          slot="start"
          class="mdi mdi-message-draw text--gray"
          style="font-size: 22px;"
        />
        <ion-text
          slot="start"
          class="text--white text-16"
        >
          {{ $t('feedback') }}
        </ion-text>
        <ion-icon
          slot="end"
          size="small"
          color="primary"
          :icon="chevronForwardOutline"
        />
      </ion-item>
      <ion-item
        v-if="userRole !== 'Landlord'"
        button
        detail="false"
        lines="none"
        class="h-65 rounded-corners mt-8"
        @click="selectedView = 2"
      >
        <i
          slot="start"
          class="mdi mdi-router-wireless text--gray"
          style="font-size: 22px;"
        />
        <ion-text
          slot="start"
          class="text--white text-16"
        >
          {{ $t('myGateway') }}
        </ion-text>
        <ion-icon
          slot="end"
          size="small"
          color="primary"
          :icon="chevronForwardOutline"
        />
      </ion-item>
      <ion-item
        v-if="userRole !== 'Landlord'"
        detail="false"
        button
        lines="none"
        class="h-65 rounded-corners mt-8"
        @click="selectedView = 3"
      >
        <i
          slot="start"
          class="mdi mdi-bell-ring text--gray"
          style="font-size: 22px;"
        />
        <ion-text
          slot="start"
          class="text--white text-16"
        >
          {{ $t('notifications') }}
        </ion-text>
        <ion-icon
          slot="end"
          size="small"
          color="primary"
          :icon="chevronForwardOutline"
        />
      </ion-item>
      <ion-item
        v-if="userRole !== 'Landlord'"
        detail="false"
        button
        lines="none"
        class="h-65 rounded-corners mt-8"
        @click="selectedView = 5"
      >
        <i
          slot="start"
          class="mdi mdi-map-marker-outline text--gray"
          style="font-size: 22px;"
        />
        <ion-text
          slot="start"
          class="text--white text-16"
        >
          Geofence
        </ion-text>
        <ion-icon
          slot="end"
          size="small"
          color="primary"
          :icon="chevronForwardOutline"
        />
      </ion-item>
      <ion-item
        v-if="userRole === 'Landlord'"
        detail="false"
        button
        lines="none"
        class="h-65 rounded-corners mt-8"
        @click="selectedView = 8"
      >
        <i
          slot="start"
          class="mdi mdi-cog-outline text--gray"
          style="font-size: 22px;"
        />
        <ion-text
          slot="start"
          class="text--white text-16"
        >
          Variables
        </ion-text>
        <ion-icon
          slot="end"
          size="small"
          color="primary"
          :icon="chevronForwardOutline"
        />
      </ion-item>
      <div class="version">
        <span style="position: relative; left: -50%;">API Version: {{ APIVersion }}</span>
      </div>
    </div>
    <!-- gateway settings -->
    <div v-else-if="selectedView === 2">
      <div>
        <div class="mt-20">
          <img
            style="margin: 0 auto;"
            :src="require('../assets/devices/gateway.png')"
          >
        </div>
      </div>
      <div class="text-18 text--white mt-30">
        Status: {{ $store.state.userProperties[0].gatewayActive ? $t('Active') : $t('Inactive') }}
      </div>
      <ion-item
        v-if="$store.state.userRole === 'Home Owner'"
        detail="false"
        button
        lines="none"
        color="danger"
        class="h-65 rounded-corners mt-30"
        @click="selectedView = 4"
      >
        <ion-text
          slot="start"
          class="text--white text-16"
        >
          {{ $t('Remove Gateway') }}
        </ion-text>
        <ion-icon
          slot="end"
          size="small"
          color="primary"
          :icon="chevronForwardOutline"
        />
      </ion-item>
      <div class="text-left pt-5 pb-5">
        <!-- <span class="text--gray text-14">{{ $t('descriptionWhenRemovingGateway') }}</span> -->
      </div>
    </div>
    <!-- gateway reset -->
    <div v-else-if="selectedView === 4">
      <div class="text--white text-18 text--bold">
        <!-- Reseting your gateway -->
      </div>
      <div class="mt-30 text--white">
        {{ $t('Please make sure you understand folowing') }}: <br>
        <ion-item
          color="transparent"
          lines="none"
          class="text--white mt-10 text-16"
        >
          <i class="mdi mdi-alert text--primary text-24 pr-5" />{{ $t('This action will delete all your scenes') }} <br>
        </ion-item>
        <ion-item
          color="transparent"
          lines="none"
          class="text--white text-16"
        >
          <i class="mdi mdi-alert text--primary text-24 pr-5" />{{ $t('This action will delete all your rooms') }} <br>
        </ion-item>
        <ion-item
          color="transparent"
          lines="none"
          class="text--white text-16"
        >
          <i class="mdi mdi-alert text--primary text-24 pr-5" />{{ $t('This action will delete all your devices') }} <br>
        </ion-item>
        <ion-item
          color="transparent"
          lines="none"
          class="text--white text-16"
        >
          <i class="mdi mdi-alert text--primary text-24 pr-5" />{{ $t('You will need to restart the gateway') }}<br>
        </ion-item>
        <div class="mt-30">
          <span class="text--white">{{ $t('Once process completed you will be logged out and need to log back in') }}</span>
        </div>
        <div class="mt-30">
          <ion-item
            color="transparent"
            lines="none"
            class="text--primary"
          >
            <ion-checkbox v-model="understand" /> {{ $t('Understand and wish to continue') }}
          </ion-item>
          <ion-item
            color="transparent"
            lines="none"
            class="text--primary"
          >
            <ion-checkbox v-model="gatewayReset" /> {{ $t('I have restarted my gateway') }}
          </ion-item>
        </div>
        <div class="mt-30">
          <ion-button
            :disabled="!understand || !gatewayReset || loading"
            color="danger"
            @click="restartGateway"
          >
            <ion-spinner
              v-if="loading"
              name="crescent"
            />
            <span v-else>{{ $t('Remove my gateway') }}</span>
          </ion-button>
        </div>
      </div>
    </div>
    <!-- notifications -->
    <div v-else-if="selectedView === 3">
      <div
        v-if="selectedViewNotification === 1"
        class="text-left text--gray pb-10"
      >
        {{ $t('notificationSettingText') }}
      </div>
      <div
        v-else
        class="text--white pb-10"
      >
        {{ notificationToEdit.deviceName }} <span class="text-14 text--gray">{{ nameMapper(notificationToEdit.zigbeeModel) }}</span>
      </div>
      <div v-if="selectedViewNotification === 1">
        <ion-item
          v-for="{id, deviceName, notification, notificationWhenScene, zigbeeModel} in devices"
          :key="id"
          detail="false"
          lines="none"
          color="transparent"
          class="device-item rounded-corners mt-8 text--white"
          @click="notificationView(id, deviceName, notification, notificationWhenScene, zigbeeModel)"
        >
          <ion-text
            slot="start"
            class="text--white"
          >
            {{ deviceName }}<br><span class="text-12 text--gray">{{ nameMapper(zigbeeModel) }}</span>
          </ion-text>
          <!-- <ion-toggle
            slot="end"
            :value="notification ? false : true"
            :checked="notification"
            @ionChange="notificationSetting($event.target.value, id)"
          /> -->
          <ion-text
            slot="end"
            class="text--white"
          >
            {{ notification ? $t('On') : $t('Off') }}
          </ion-text>
          <ion-icon
            slot="end"
            size="small"
            color="primary"
            :icon="chevronForwardOutline"
          />
        </ion-item>
        <div class="text-left text--primary mt-30">
          {{ $t('General Notifications') }}
        </div>
        <ion-item
          detail="false"
          lines="none"
          color="transparent"
          class="device-item rounded-corners mt-8 text--white"
        >
          <ion-text
            slot="start"
            class="text--white"
          >
            {{ $t('alarmNotifcation') }}
          </ion-text>
          <ion-toggle
            slot="end"
            :value="userProperties.pushNotificationWhenAlarm ? false : true"
            :checked="userProperties.pushNotificationWhenAlarm"
            @ionChange="controlPushWhenAlarm($event.target.value)"
          />
        </ion-item>
        <div class="text-left pt-5 pb-5">
          <span class="text--gray text-14">{{ $t('descriptionWhenAlarm') }}</span>
        </div>
        <ion-item
          detail="false"
          lines="none"
          color="transparent"
          class="device-item rounded-corners mt-8 text--white"
        >
          <ion-text
            slot="start"
            class="text--white"
          >
            {{ $t('Notification scene change') }}
          </ion-text>
          <ion-toggle
            slot="end"
            :value="userProperties.pushWhenSceneChange ? false : true"
            :checked="userProperties.pushWhenSceneChange"
            @ionChange="controlPushWhenSceneChange($event.target.value)"
          />
        </ion-item>
        <div class="text-left pt-5 pb-5">
          <span class="text--gray text-14">{{ $t('descriptionWhenSceneChange') }}</span>
        </div>
        <ion-item
          detail="false"
          lines="none"
          color="transparent"
          class="device-item rounded-corners mt-8 text--white"
        >
          <ion-text
            slot="start"
            class="text--white"
          >
            {{ $t('Notification alarm change') }}
          </ion-text>
          <ion-toggle
            slot="end"
            :value="userProperties.pushWhenAlarmChange ? false : true"
            :checked="userProperties.pushWhenAlarmChange"
            @ionChange="controlPushWhenAlarmChange($event.target.value)"
          />
        </ion-item>
        <div
          class="text-left pt-5"
          style="padding-bottom: 50px;"
        >
          <span class="text--gray text-14">{{ $t('alarmStatusNotification') }}</span>
        </div>
      </div>
      <div v-else>
        <ion-item
          detail="false"
          lines="none"
          color="transparent"
          class="device-item rounded-corners mt-8 text--white"
        >
          <ion-label slot="start">
            {{ $t('Allow notifications') }}
          </ion-label>
          <ion-toggle
            slot="end"
            :value="notificationToEdit.notification ? false : true"
            :checked="notificationToEdit.notification"
            @ionChange="notificationSetting($event.target.value, notificationToEdit.id)"
          />
        </ion-item>
        <ion-item
          lines="none"
          color="transparent"
          class="device-item rounded-corners mt-8 text--white"
        >
          <ion-label>
            {{ $t('When scene is') }}
          </ion-label>
          <ion-select
            v-model="notificationToEdit.notificationWhenScene"
            :placeholder="$t('Off')"
            @ionChange="notificationWhenSceneChange($event.target.value, notificationToEdit)"
          >
            <ion-select-option
              key="Off"
              value="Off"
            >
              {{ $t('Off') }}
            </ion-select-option>
            <ion-select-option
              v-for="{sceneName} in propertyScenes"
              :key="sceneName"
              :value="sceneName"
            >
              {{ sceneName }}
            </ion-select-option>
          </ion-select>
          <i class="mdi mdi-chevron-down text--primary pl-10" />
        </ion-item>
        <!-- <ion-item
          button
          detail="false"
          lines="none"
          class="h-65 rounded-corners mt-8 text--white"
        >
          <ion-label>
            People
          </ion-label>
          <ion-select placeholder="Select One">
            <ion-select-option
              v-for="{option} in peopleOptions"
              :key="option"
              :value="option"
            >
              {{ option }}
            </ion-select-option>
          </ion-select>
        </ion-item> -->
        <div class="mt-10 text-14 text-left text--gray">
          {{ $t('notificationSettingHelpText') }}
        </div>
      </div>
    </div>
    <!-- Geo fencing -->
    <div v-else-if="selectedView === 5">
      <ion-item
        detail="false"
        lines="none"
        color="transparent"
        class="device-item rounded-corners mt-8 text--white"
      >
        <ion-label slot="start">
          {{ $t('Allow geofence') }}
        </ion-label>
        <ion-toggle
          slot="end"
          :disabled="!locationPermissions"
          :value="geofenceEnabled ? false : true"
          :checked="geofenceEnabled"
          @ionChange="turnOnGeolocationFence($event.target.value)"
        />
      </ion-item>
      <ion-item
        v-if="geofenceEnabled"
        button
        detail="false"
        lines="none"
        class="h-65 rounded-corners mt-8 text--white"
      >
        <ion-label>
          {{ $t('Geofence radius') }}
        </ion-label>
        <ion-select
          v-model="radius"
          placeholder="Select One"
        >
          <ion-select-option
            v-for="{value, name} in radiusOptions"
            :key="value"
            :value="value"
          >
            {{ name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <div
        v-if="geofenceEnabled"
        lines="none"
        class="mt-10 mb-10 rounded-corners"
        style="height: 45vh; z-index: 10;"
      >
        <GMapMap
          class="rounded-corners"
          :center="center"
          :options="mapOptions"
          :zoom="16"
          map-type-id="terrain"
          style="width: 100%; height: 100%; z-index: -10; border-radius: 10px;"
        >
          <GMapMarker
            :position="center"
            :clickable="true"
            :draggable="true"
            @dragend="newPosition($event)"
          />
          <GMapCircle
            :options="circleOptions"
            :radius="radius"
            :center="center"
          />
        </GMapMap>
      </div>
      <ion-button
        v-if="geofenceEnabled && !userData.geofenceEnabled"
        @click="initGeofence"
      >
        <ion-label v-if="!loading">
          {{ $t('Add geofence') }}
        </ion-label>
        <ion-spinner
          v-else
          name="crescent"
        />
      </ion-button>
      <!-- <ion-button
        v-if="geofenceEnabled && userData.geofenceEnabled "
        @click="initGeofence"
      >
        <ion-label v-if="!loading">
          {{ $t('Update geofence') }}
        </ion-label>
        <ion-spinner
          v-else
          name="crescent"
        />
      </ion-button> -->
      <ion-button
        v-if="userData.geofenceEnabled && geofenceEnabled"
        @click="removeGeofece"
      >
        <ion-label v-if="!loading">
          {{ $t('Remove geofence') }}
        </ion-label>
        <ion-spinner
          v-else
          name="crescent"
        />
      </ion-button>
      <div
        v-if="!locationPermissions"
        class="mt-30"
      >
        <div v-if="platform.includes('android')">
          <span class="text--white">{{ $t('Geolocation disabled, if you wish to use geofence please click on button below') }}</span>
          <ion-button
            class="mt-30"
            @click="requestAndroidPermission"
          >
            {{ $t('Request permissions') }}
          </ion-button>
        </div>
        <div v-else-if="platform.includes('ios')">
          <span class="text--white">{{ $t('Geolocation disabled, if you wish to use geofence please click on button belowIOS') }}</span>
          <ion-button
            class="mt-30"
            @click="openIosPermissions"
          >
            {{ $t('Allow Location') }}
          </ion-button>
        </div>
        <div v-else>
          <span>Not supported platform</span>
        </div>
      </div>
    </div>
    <!-- App settings -->
    <div v-else-if="selectedView === 6">
      <ion-item
        class="device-item rounded-corners text--white"
        color="transparent"
        lines="none"
      >
        <ion-label>{{ $t('appLanguage') }}</ion-label>
        <ion-select
          v-model="language"
          placeholder="Select One"
        >
          <ion-select-option value="EN">
            English
          </ion-select-option>
          <ion-select-option value="SE">
            Svenska
          </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item
        color="transparent"
        class="device-item rounded-corners text--white mt-8"
        lines="none"
      >
        <ion-label>{{ $t('homevisionNewsletter') }}</ion-label>
        <ion-toggle />
      </ion-item>
      <ion-item
        detail="false"
        lines="none"
        color="transparent"
        class="device-item rounded-corners mt-30"
        @click="selectedView = 7"
      >
        <ion-text
          slot="start"
          class="text--primary text-16"
        >
          {{ $t('Remove my account') }}
        </ion-text>
        <ion-icon
          slot="end"
          size="small"
          color="primary"
          :icon="chevronForwardOutline"
        />
      </ion-item>
    </div>
    <!-- Remove Account -->
    <div v-else-if="selectedView === 7">
      <div
        v-if="userData.role === 'Home Owner'"
        class="text--primary text--uppercase"
      >
        <span class="text-22">{{ $t('defaultHomeUser') }}</span><br><br>
        {{ $t('deletingAccountHomeOwnerText1') }}<br><br>
        {{ $t('deletingAccountHomeOwnerText2') }}
      </div>
      <div
        v-else-if="userData.role === 'Tenant'"
        class="text--primary text--uppercase"
      >
        <span class="text-22">{{ $t('defaultHomeUser') }}</span><br><br>
        {{ $t('deletingAccountTenantText1') }}<br><br>
        {{ $t('deletingAccountTenantText2') }}
      </div>
      <div
        v-else
        class="text--primary text--uppercase"
      >
        {{ $t('Are you sure you want to delete your account?') }}
      </div>
      <div class="mt-30">
        <ion-item
          color="transparent"
          lines="none"
          class="text--primary"
        >
          <ion-checkbox v-model="understandToDeleteMyAccount" /> {{ $t('Understand and wish to continue') }}
        </ion-item>
      </div>
      <ion-button
        class="mt-30"
        :disabled="!understandToDeleteMyAccount"
        color="danger"
        @click="deleteMyAccount(userData)"
      >
        <ion-spinner
          v-if="loading"
          name="crescent"
        />
        <span v-else>{{ $t('Remove my account') }}</span>
      </ion-button>
    </div>
    <!-- Landlord Variuables -->
    <div v-else-if="selectedView === 8">
      <ion-item
        lines="none"
        color="transparent"
        class="device-item rounded-corners mt-8 text--white"
      >
        <ion-label>
          Fire notification delay
        </ion-label>
        <ion-select
          v-model="userData.fireNotificationDelay"
          @ionChange="fireNotificationDelayLandlord($event.target.value)"
        >
          <ion-select-option
            v-for="{time, name} in timeDelay"
            :key="time"
            :value="time"
          >
            {{ name }}
          </ion-select-option>
        </ion-select>
        <i class="mdi mdi-chevron-down text--primary pl-10" />
      </ion-item>
      <div class="text-left pt-5 pb-5">
        <span class="text--gray text-14">It can happen to anyone, somebody can leave slice of bread in toaster
          bit longer and it may cause false fire alarm. This setting allows you to create some kind of time
          buffer and you wont be bothered unless the alarm persists for longer than e.g. 30 seconds. </span>
      </div>
      <ion-item
        lines="none"
        color="transparent"
        class="device-item rounded-corners mt-8 text--white"
      >
        <ion-label>
          Waterleak notification delay
        </ion-label>
        <ion-select
          v-model="userData.waterLeakNotificationDelay"
          @ionChange="waterLeakNotificationDelayLandlord($event.target.value)"
        >
          <ion-select-option
            v-for="{time, name} in timeDelay"
            :key="time"
            :value="time"
          >
            {{ name }}
          </ion-select-option>
        </ion-select>
        <i class="mdi mdi-chevron-down text--primary pl-10" />
      </ion-item>
      <div class="text-left pt-5 pb-5">
        <span class="text--gray text-14">It can happen to anyone, somebody can leave slice of bread in toaster
          bit longer and it may cause false fire alarm. This setting allows you to create some kind of time
          buffer and you wont be bothered unless the alarm persists for longer than e.g. 30 seconds. </span>
      </div>
      <ion-item
        lines="none"
        color="transparent"
        class="device-item rounded-corners mt-8 text--white"
      >
        <ion-label>
          Climate alert if temperature is below
        </ion-label>
        <ion-select
          v-model="userData.climateWarningBelow"
          @ionChange="climateWarningBelowLandlord($event.target.value)"
        >
          <ion-select-option
            v-for="{temp, name} in climateWarning"
            :key="temp"
            :value="temp"
          >
            {{ name }}
          </ion-select-option>
        </ion-select>
        <i class="mdi mdi-chevron-down text--primary pl-10" />
      </ion-item>
      <ion-item
        lines="none"
        color="transparent"
        class="device-item rounded-corners mt-8 text--white"
      >
        <ion-label>
          Climate alert if temperature is above
        </ion-label>
        <ion-select
          v-model="userData.climateWarningAbove"
          @ionChange="climateWarningAboveLandlord($event.target.value)"
        >
          <ion-select-option
            v-for="{temp, name} in climateWarning"
            :key="temp"
            :value="temp"
          >
            {{ name }}
          </ion-select-option>
        </ion-select>
        <i class="mdi mdi-chevron-down text--primary pl-10" />
      </ion-item>
      <div class="text-left pt-5 pb-5">
        <span class="text--gray text-14">We will let you know if any of the apartments overheating or if there is too cold </span>
      </div>
    </div>
  </ion-content>
</template>

<script>
// import { Plugins } from '@capacitor/core'
import {
  closeOutline,
  addCircleOutline,
  checkmarkDoneOutline,
  closeCircleOutline,
  chevronForwardOutline
} from 'ionicons/icons'
import { defineComponent } from 'vue'
import { Geofence } from '@ionic-native/geofence/'
import BackgroundGeolocation from '@transistorsoft/capacitor-background-geolocation'
import { Geolocation } from '@ionic-native/geolocation/'
import { AndroidPermissions } from '@ionic-native/android-permissions'
import { Diagnostic } from '@ionic-native/diagnostic'
import { modalController } from '@ionic/vue'

import { PushNotifications } from '@capacitor/push-notifications'

import feedbackModal from './FeedbackModal.vue'

import $socket from '../lib/socketIo'

export default defineComponent({
  components: {
  },
  props: {
    close: { type: Function, default: null }
  },
  data () {
    return {
      APIVersion: 0,
      diagnostic: Diagnostic,
      androidPermissions: AndroidPermissions,
      geolocation: Geolocation,
      closeOutline,
      addCircleOutline,
      checkmarkDoneOutline,
      closeCircleOutline,
      chevronForwardOutline,
      selectedView: 1,
      selectedViewNotification: 1,
      notificationToEdit: {},
      peopleOptions: [
        { option: 'No one is at home' }
      ],
      gatewayReset: false,
      understand: false,
      loading: false,
      understandToDeleteMyAccount: false,
      geofenceEnabled: false,
      locationPermissions: false,
      mapOptions: {
        styles: [
          { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
          { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
          { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
          {
            featureType: 'administrative.locality',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }]
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }]
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [{ color: '#263c3f' }]
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#6b9a76' }]
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{ color: '#38414e' }]
          },
          {
            featureType: 'road',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#212a37' }]
          },
          {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#9ca5b3' }]
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{ color: '#746855' }]
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#1f2835' }]
          },
          {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#f3d19c' }]
          },
          {
            featureType: 'transit',
            elementType: 'geometry',
            stylers: [{ color: '#2f3948' }]
          },
          {
            featureType: 'transit.station',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }]
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{ color: '#17263c' }]
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#515c6d' }]
          },
          {
            featureType: 'water',
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#17263c' }]
          }
        ]
      },
      geofenceId: 0,
      radius: 200,
      center: { lat: 49.05086915589208, lng: 18.923319988021966 },
      radiusOptions: [
        { value: 200, name: '200 Meter' },
        { value: 250, name: '250 Meter' },
        { value: 300, name: '300 Meter' }
      ],
      circleOptions: {
        strokeColor: '#ffd4a4',
        strokeOpacity: 0.5,
        strokeWeight: 0.5,
        fillColor: '#ffd4a4',
        fillOpacity: 0.35
      },
      event: 'Events',
      timeDelay: [
        { time: 30, name: '30 sec' },
        { time: 60, name: '1 min' },
        { time: 120, name: '2 min' }
      ],
      climateWarning: [
        { temp: 17, name: '17°C' },
        { temp: 18, name: '18°C' },
        { temp: 19, name: '19°C' },
        { temp: 20, name: '20°C' },
        { temp: 21, name: '21°C' },
        { temp: 22, name: '22°C' },
        { temp: 23, name: '23°C' },
        { temp: 24, name: '24°C' },
        { temp: 25, name: '25°C' },
        { temp: 26, name: '26°C' }
      ]
    }
  },
  computed: {
    language: {
      get () {
        return this.$store.state.language
      },
      set (value) {
        const language = value
        this.$i18n.locale = language
        this.$store.dispatch('changeAppLanguage', { language: language })
      }
    },
    propertyScenes () {
      return this.$store.state.propertyScenes
    },
    devices () {
      return this.$store.state.propertyDevices.filter(item => item.security === true || item.alarm === true)
    },
    selectedViewName () {
      const view = this.selectedView
      let selectedView = ''
      if (view === 1) {
        selectedView = this.$t('settingsTitle')
      }
      if (view === 2) {
        selectedView = this.$t('myGateway')
      }
      if (view === 3) {
        selectedView = this.$t('notifications')
      }
      if (view === 4) {
        selectedView = ''
      }
      if (view === 5) {
        selectedView = this.$t('geoFencing')
      }
      return selectedView
    },
    userData () {
      // console.log(this.$store.state.userData[0])
      return this.$store.state.userData[0]
    },
    userProperties () {
      return this.$store.state.userProperties[0]
    },
    userRole () {
      return this.$store.state.userRole
    },
    platform () {
      return this.$store.state.platform
    }
  },
  watch: {
    selectedView: function (value) {
      console.log(value)
      if (value === 5) {
        if (this.platform.includes('android')) {
          this.checkAndroidPermissions()
        } else if (this.platform.includes('ios')) {
          this.checkIosPermissions()
        }
      }
    }
  },
  created () {
    if (this.userData.geofenceEnabled && this.userData.role !== 'Landlord') {
      this.geofenceEnabled = true
      const geofenceData = this.userData.geofenceData
      this.center = { lat: geofenceData.lat, lng: geofenceData.lng }
      this.radius = geofenceData.radius
    }
    this.getAPIVersion()
  },
  methods: {
    getAPIVersion () {
      this.$store.dispatch('getAPIVersion').then((resp) => {
        this.APIVersion = resp.data.version
      })
    },
    restartGateway () {
      this.loading = true
      this.$store.dispatch('restartGateway').then(() => {
        this.$store.dispatch('LogOut').then(() => {
          PushNotifications.removeAllListeners()
          this.dismissModal()
          this.$router.push('/login')
        })
      })
        .catch(error => {
          console.log(error)
        })
    },
    notificationSetting (value, id) {
      console.log(value + ' ' + id)
      let setting = false
      if (value === 'true') {
        setting = true
      } else {
        setting = false
      }
      this.$store.dispatch('notificationSetting', { setting, id })
    },
    notificationWhenSceneChange (value, device) {
      console.log(value)
      console.log(device)
      this.$store.dispatch('notificationWhenScene', { value, deviceId: device.id })
    },
    nameMapper (name) {
      if (name === 'SensorDoorWindow') {
        return this.$t('doorWindowSensor')
      } else if (name === 'SensorWaterLeak') {
        return this.$t('leakSensor')
      } else if (name === 'SensorMotion') {
        return this.$t('motionSensor')
      } else if (name === 'SensorSmoke') {
        return this.$t('smokeSensor')
      }
    },
    notificationView (id, deviceName, notification, notificationWhenScene, zigbeeModel) {
      this.selectedViewNotification = 2
      this.notificationToEdit = {
        id,
        deviceName,
        notification,
        notificationWhenScene,
        zigbeeModel
      }
    },
    controlPushWhenAlarm (value) {
      let setting = false
      if (value === 'true') {
        setting = true
      } else {
        setting = false
      }
      this.$store.dispatch('controlPushNotificationWhenAlarm', { setting })
    },
    controlPushWhenSceneChange (value) {
      let setting = false
      if (value === 'true') {
        setting = true
      } else {
        setting = false
      }
      this.$store.dispatch('controlPushWhenSceneChange', { setting })
    },
    controlPushWhenAlarmChange (value) {
      let setting = false
      if (value === 'true') {
        setting = true
      } else {
        setting = false
      }
      this.$store.dispatch('controlPushWhenAlarmChange', { setting })
    },
    deleteMyAccount (user) {
      console.log(user)
      const id = user.id
      const role = user.role
      this.loading = true
      this.$store.dispatch('removeMyAccount', { id, role }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          this.$router.replace('/signup')
          $socket.disconnect()
          PushNotifications.removeAllListeners()
          this.dismissModal()
        })
      })
    },
    newPosition ($event) {
      console.log('event')
      this.$nextTick(() => {
        this.center.lat = $event.latLng.lat()
        this.center.lng = $event.latLng.lng()
      })
      console.log(this.center.lat)
    },
    turnOnGeolocationFence (value) {
      if (value === 'true') {
        if (this.$store.state.initLocation.lat) {
          this.center = this.$store.state.initLocation
          console.log(this.center)
          this.geofenceEnabled = true
        } else {
          console.log('request location')
          this.geolocation.getCurrentPosition().then((resp) => {
            console.log(resp.coords.latitude)
            console.log(resp.coords.longitude)
            this.$store.commit('initLocation', { lat: resp.coords.latitude, lng: resp.coords.longitude })
            this.center = { lat: resp.coords.latitude, lng: resp.coords.longitude }
            console.log(this.center)
            this.geofenceEnabled = true
          }).catch((error) => {
            console.log('Error getting location', error)
          })
        }
      } else {
        this.geofenceEnabled = false
        this.removeGeofece()
      }
    },
    async initGeofence () {
      this.loading = true
      const center = this.center
      const lat = center.lat
      const lng = center.lng
      BackgroundGeolocation.addGeofence({
        identifier: 'Home',
        radius: this.radius,
        latitude: lat,
        longitude: lng,
        notifyOnEntry: true,
        notifyOnExit: true,
        extras: {
          userId: this.$store.state.userId
        }
      }).then((success) => {
        console.log('geofence init')
        const status = true
        this.addGeofence(status)
      }).catch((error) => {
        console.log('[addGeofence] FAILURE: ', error)
      })
    },
    async addGeofence (status) {
      if (status) {
        BackgroundGeolocation.startGeofences()
        console.log('Geofence added')
        this.$store.dispatch('geofenceAdded', { lat: this.center.lat, lng: this.center.lng, radius: this.radius })
        this.loading = false
      }
      this.loading = false
    },
    removeGeofece () {
      BackgroundGeolocation.removeGeofences().then(success => {
        BackgroundGeolocation.stop()
        Geofence.removeAll() // REMOVE
        console.log('geofence removed')
        this.geofenceId = 0
        this.$store.dispatch('removeGeofence')
      })
        .catch(error => console.log(error, 'fence error'))
    },
    fireNotificationDelayLandlord (value) {
      this.$store.dispatch('notificationDelaysLandlord', { value, type: 'fireNotificationDelay' })
    },
    waterLeakNotificationDelayLandlord (value) {
      this.$store.dispatch('notificationDelaysLandlord', { value, type: 'waterLeakNotificationDelay' })
    },
    climateWarningBelowLandlord (value) {
      this.$store.dispatch('settingVariables', { value, type: 'climateWarningBelow' })
    },
    climateWarningAboveLandlord (value) {
      this.$store.dispatch('settingVariables', { value, type: 'climateWarningAbove' })
    },
    dismissModal () {
      this.close()
    },
    checkAndroidPermissions () {
      this.androidPermissions.checkPermission('android.permission.ACCESS_BACKGROUND_LOCATION').then(
        result => {
          console.log('Android status permissions')
          console.log('PermissionResult          ' + JSON.stringify(result))
          if (!result.hasPermission) {
            this.locationPermissions = false
          } else {
            this.locationPermissions = true
          }
        },
        err => {
          console.log(err)
        }
      )
    },
    requestAndroidPermission () {
      this.androidPermissions.requestPermissions(
        [
          this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION,
          'android.permission.ACCESS_BACKGROUND_LOCATION'
        ]
      ).then(
        this.$nextTick(() => {
          this.androidPermissions.checkPermission('android.permission.ACCESS_BACKGROUND_LOCATION').then(
            result => {
              console.log('Android status permissions')
              console.log('PermissionResult          ' + JSON.stringify(result))
              if (!result.hasPermission) {
                this.locationPermissions = false
              } else {
                this.locationPermissions = true
              }
            },
            err => {
              console.log(err)
            }
          )
        })
      )
    },
    checkIosPermissions () {
      this.diagnostic.isLocationAuthorized().then((isEnabled) => {
        if (isEnabled) {
          this.locationPermissions = true
        } else {
          this.locationPermissions = false
        }
      })
    },
    openIosPermissions () {
      window.cordova.plugins.settings.open('locations', () => {
        console.log('wifi settings opened')
      })
      this.locationPermissions = true
    },
    openFeedbackModal () {
      return modalController
        .create({
          component: feedbackModal,
          cssClass: 'devices-modal',
          swipeToClose: true,
          showBackdrop: true,
          backdropDismiss: true,
          componentProps: {
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    closeModal () {
      modalController.dismiss()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";

.h-65 {
   --min-height: 65px;
   @include bg-box-item;
}

#mapid { height: 180px; }

.version {
    position: absolute;
    bottom: 20px;
    left: 50%;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.6px;
    color: rgba(255, 255, 255, 0.288);
}
</style>
