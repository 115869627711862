<template>
  <div class="background--modal" />
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar color="transparent">
        <ion-button
          v-if="selectedView === 1"
          slot="start"
          fill="clear"
          color="transparent"
          size="large"
          class="close-modal-button"
          @click="dismissModal"
        >
          <img src="../assets/images/arrow-go-back-line.svg">
        </ion-button>
        <ion-button
          v-else-if="selectedView < 4"
          slot="start"
          fill="clear"
          color="transparent"
          size="large"
          class="close-modal-button"
          @click="selectedView--"
        >
          <img src="../assets/images/arrow-go-back-line.svg">
        </ion-button>
        <ion-title class="text--white text-18 text--400">
          {{ $t('AddDeviceTitle') }}
        </ion-title>
        <ion-button
          v-if="selectedView === 1"
          slot="end"
          fill="clear"
          :disabled="!selectedDevice"
          color="transparent"
          size="large"
          class="close-modal-button"
          @click="selectedView++"
        >
          <ion-text
            class="text-16 text--400"
            color="primary"
          >
            {{ $t('next') }}
          </ion-text>
        </ion-button>
        <ion-button
          v-if="selectedView === 2"
          slot="end"
          fill="clear"
          :disabled="!deviceName || !selectedRoom"
          color="transparent"
          size="large"
          class="close-modal-button"
          @click="selectedView++"
        >
          <ion-text
            class="text-16 text--400"
            color="primary"
          >
            {{ $t('next') }}
          </ion-text>
        </ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <div v-if="isGatewayActive === true">
        <div v-if="selectedView === 1">
          <ion-row class="">
            <ion-col
              v-for="device in availableDevices"
              :key="device.id"
              size="6"
            >
              <div
                class="device rounded-corners"
                :class="selectedDeviceTypeI === device.typeI ? 'selected' : 'none'"
                @click="selectedDevice = device.zigbeeModel; selectedDeviceTypeI = device.typeI"
              >
                <div class="percentage text-right" />
                <div>
                  <div class="device-img">
                    <img :src="require('../assets/devices/' + device.typeI + '.png')">
                  </div>
                  <p class="text-12 text--gray" />
                  <h3>
                    {{ device.name }}
                  </h3>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </div>
        <div v-else-if="selectedView === 2">
          <div
            class="connecting-device mt-20"
            :class="keyboardStatus === 'closed' ? 'keyboard-closed' : 'keyboard-opened'"
          >
            <img :src="require('../assets/devices/' + selectedDeviceTypeI + '.png')">
          </div>
          <ul>
            <li>{{ $t('pleaseSelectDeviceName') }}</li>
            <li>{{ $t('DontForgetToChoseTheRoomInWhichTheDeviceShouldBePlaced') }}</li>
          </ul>
          <div
            class="login-form form--dark flex-1"
            :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
          >
            <ion-item lines="none">
              <ion-label position="floating">
                {{ $t('deviceName') }}
              </ion-label>
              <ion-input
                v-model="deviceName"
                class="capitalize"
                type="text"
                maxlength="13"
                autocapitalize="words"
                enterkeyhint="done"
                required
                @keydown.enter="closeKeyboard"
              />
            </ion-item>
          </div>
          <div class="text-left pb-10">
            <ion-text class="text--white">
              {{ $t('room') }}
            </ion-text>
          </div>
          <div>
            <ion-grid class="ion-no-padding">
              <ion-row class="ion-no-padding">
                <div
                  v-for="room in propertyRooms"
                  :key="room.id"
                >
                  <ion-col class="ion-no-padding">
                    <div class="buttonRoom">
                      <ion-button
                        :class="selectedRoom === room.id ? 'active' : 'inactive'"
                        @click="selectedRoom = room.id"
                      >
                        {{ room.roomName }}
                      </ion-button>
                    </div>
                  </ion-col>
                </div>
              </ion-row>
            </ion-grid>
          </div>
        </div>
        <div v-else-if="selectedView === 3">
          <div class="connectDevices">
            <div class="connecting-device">
              <img :src="require('../assets/devices/' + selectedDeviceTypeI + '.png')">
            </div>
            <ul v-if="selectedDeviceTypeI === 'SensorMotion' || selectedDeviceTypeI === 'SensorWaterLeak' || selectedDeviceTypeI === 'SensorMulti' || selectedDeviceTypeI === 'SensorTemperatureIndoor' || selectedDeviceTypeI === 'SensorVibration'">
              <li>{{ $t('SensorMotionSensorWaterLeakStep1') }}</li>
              <li>{{ $t('SensorMotionSensorWaterLeakStep2') }}</li>
              <li>{{ $t('SensorMotionSensorWaterLeakStep3') }}</li>
              <li>{{ $t('SensorMotionSensorWaterLeakStep4') }}</li>
              <li>{{ $t('SensorMotionSensorWaterLeakStep5') }}</li>
              <li>{{ $t('SensorMotionSensorWaterLeakStep6') }}</li>
            </ul>
            <ul v-else-if="selectedDeviceTypeI === 'SensorSmoke'">
              <li>{{ $t('SensorSmokeStep1') }}</li>
              <li>{{ $t('SensorSmokeStep2') }}</li>
              <li>{{ $t('SensorSmokeStep3') }}</li>
              <li>{{ $t('SensorSmokeStep4') }}</li>
            </ul>
            <ul v-else-if="selectedDeviceTypeI === 'SensorDoorWindow'">
              <li>{{ $t('SensorDoorWindowStep1') }}</li>
              <li>{{ $t('SensorDoorWindowStep2') }}</li>
              <li>{{ $t('SensorDoorWindowStep3') }}</li>
              <li>{{ $t('SensorDoorWindowStep4') }}</li>
              <li>{{ $t('SensorDoorWindowStep5') }}</li>
              <li>{{ $t('SensorDoorWindowStep6') }}</li>
            </ul>
            <ul v-else-if="selectedDeviceTypeI === 'SmartPlug'">
              <li>{{ $t('SmartPlugStep1') }}</li>
              <li>{{ $t('SmartPlugStep2') }}</li>
              <li>{{ $t('SmartPlugStep3') }}</li>
              <li>{{ $t('SmartPlugStep4') }}</li>
            </ul>
            <ul v-else-if="selectedDeviceTypeI === 'SensorTemperatureOutdoor'">
              <li>{{ $t('SensorTemperatureOutdoorStep1') }}</li>
              <li>{{ $t('SensorTemperatureOutdoorStep2') }}</li>
              <li>{{ $t('SensorTemperatureOutdoorStep3') }}</li>
              <li>{{ $t('SensorTemperatureOutdoorStep4') }}</li>
            </ul>
            <ul v-else-if="selectedDeviceTypeI === 'IASWarning'">
              <li>{{ $t('IASWarningStep1') }}</li>
              <li>{{ $t('IASWarningStep2') }}</li>
              <li>{{ $t('IASWarningStep3') }}</li>
              <li>{{ $t('IASWarningStep4') }}</li>
            </ul>
            <ul v-else>
              <li>{{ $t('Please consult devices manual to start pairing process') }}</li>
            <!-- <li>{{ $t('IASWarningStep2') }}</li>
            <li>{{ $t('IASWarningStep3') }}</li> -->
            </ul>
            <div class="connect-device-content">
              <ion-item
                class="center"
                color="transparent"
                lines="none"
                :detail="false"
                button
                @click="checkedAllDone = !checkedAllDone"
              >
                <div slot="start" />
                <div slot="start" />
                <ion-label>
                  {{ $t('Confirm the device is ready') }}
                </ion-label>
                <ion-checkbox
                  slot="start"
                  :checked="checkedAllDone"
                  color="primary"
                />
              </ion-item>
              <ion-button
                type="button"
                class="primary mt-20"
                :disabled="!checkedAllDone"
                @click="addPropertyDevice"
              >
                {{ $t('Connect') }}
              </ion-button>
            </div>
          </div>
        </div>
        <div v-else-if="selectedView === 4">
          <div
            class="loader-wrapper"
            style="margin-top: 20vh"
          >
            <div class="back-circle-loader">
              <div
                v-if="deviceAddStatus === null"
                class="loader-anim"
              />
              <div class="inner-loader" />
              <div class="inner-loader-frame" />
            </div>
            <div class="laoder-percentage">
              <span
                v-if="deviceAddStatus === 'success'"
                style="font-size: 74px;"
                class="text--primary"
              ><i class="mdi mdi-check-all" /></span>
              <span
                v-else-if="deviceAddStatus === 'error'"
                style="font-size: 74px;"
                class="text--primary"
              >
                <i class="mdi mdi-alert-circle" />
              </span>
              <span v-else>{{ progress }}%</span>
            </div>
          </div>
          <div>
            <p class="text--white mt-30">
              <span v-if="!deviceAddStatus">{{ $t('addingTheDeviceHoldOn') }}</span>
            </p>
          </div>
          <ion-button
            v-if="deviceAddStatus === 'success'"
            class="mt-30"
            @click="dismissModal"
          >
            {{ $t('HurrayDeviceAdded') }}
          </ion-button>
          <ion-button
            v-if="deviceAddStatus === 'error'"
            class="mt-30"
            @click="resetAddingDevice"
          >
            {{ $t('Something has failed please try that again') }}
          </ion-button>
          <ion-button
            v-if="deviceAddStatus === 'error'"
            class="mt-30"
            color="medium"
            @click="dismissModal"
          >
            {{ $t('close') }}
          </ion-button>
          <div class="mt-10">
            <ion-text
              v-if="deviceAddStatus === 'error'"
              class="text--gray"
            >
              {{ $t('Please make sure you reset the device') }}
            </ion-text>
          </div>
        </div>
      </div>
      <div v-else>
        <i
          style="font-size: 50px;"
          class="mdi mdi-alert-circle-outline text--primary"
        />
        <div class="text--white mt-30">
          {{ $t('Your gateway seems to be disconnected') }}
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { closeOutline } from 'ionicons/icons'
import { defineComponent } from 'vue'
import { Keyboard } from '@capacitor/keyboard'

export default defineComponent({
  name: 'AddDeviceModal',
  props: {
    roomId: { type: Number, default: null },
    close: { type: Function, default: null }
  },
  data () {
    return {
      closeOutline,
      selectedRoom: null,
      selectedDevice: '',
      selectedDeviceTypeI: '',
      deviceName: '',
      progress: 0,
      selectedView: 1,
      checkedAllDone: false,
      availableDevices: [
        { zigbeeModel: 'SensorDoorWindow', typeI: 'SensorDoorWindow', name: this.$t('doorWindowSensor') },
        { zigbeeModel: 'SensorMotion', typeI: 'SensorMulti', name: this.$t('multiSensor') },
        { zigbeeModel: 'SensorMotion', typeI: 'SensorMotion', name: this.$t('motionSensor') },
        { zigbeeModel: 'SensorWaterLeak', typeI: 'SensorWaterLeak', name: this.$t('leakSensor') },
        { zigbeeModel: 'SensorTemperatureOutdoor', typeI: 'SensorTemperatureOutdoor', name: this.$t('sensorTemperatureOutdoor') },
        { zigbeeModel: 'SmartLightColorDimmable', typeI: 'SmartLightColorDimmable', name: this.$t('dimmableBulb') },
        { zigbeeModel: 'SmartLightColorChangeable', typeI: 'SmartLightColorChangeable', name: this.$t('colorBulb') },
        { zigbeeModel: 'SensorSmoke', typeI: 'SensorSmoke', name: this.$t('smokeSensor') },
        { zigbeeModel: 'SensorVibration', typeI: 'SensorVibration', name: this.$t('sensorVibration') },
        // { zigbeeModel: 'WallSwitch', name: this.$t('wallSwitch') },
        { zigbeeModel: 'SmartPlug', typeI: 'SmartPlug', name: this.$t('smartPlug') },
        { zigbeeModel: 'IASWarning', typeI: 'IASWarning', name: this.$t('smartSiren') },
        { zigbeeModel: 'SensorTemperatureOutdoor', typeI: 'SensorTemperatureIndoor', name: this.$t('sensorTemperatureIndoor') }
      ]
    }
  },
  computed: {
    platform () {
      return this.$store.state.platform
    },
    propertyRooms () {
      return this.$store.state.propertyRooms
    },
    deviceAddStatus () {
      return this.$store.state.deviceAddStatus
    },
    keyboardStatus () {
      return this.$store.state.keyboardStatus
    },
    isGatewayActive () {
      return this.$store.state.userProperties.length ? this.$store.state.userProperties[0].gatewayActive : undefined
    }
  },
  created () {
    if (this.roomId) {
      this.selectedRoom = this.roomId
    }
    this.progress = 0
    this.checkedAllDone = false
    this.$store.dispatch('resetAddingDeviceStatus')
  },
  methods: {
    dismissModal () {
      this.progress = 0
      this.checkedAllDone = false
      this.$store.dispatch('resetAddingDeviceStatus')
      this.close()
    },
    async progressPlus (number) {
      const add = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms))
      }
      for (let i = 0; i < number; i++) {
        await add(350)
        this.progress++
      }
    },
    addPropertyDevice () {
      if (!this.selectedDevice || !this.deviceName || !this.selectedRoom) {
        console.log('missing data')
      } else {
        let tunable
        let security
        let alarm
        let status
        let temperatureSensor
        if (this.selectedDevice === 'IASWarning') {
          status = 'inactive'
        } else if (this.selectedDevice === 'SensorMotion') {
          status = 'No Movement'
        } else if (this.selectedDevice === 'SensorVibration') {
          status = 'No Movement'
        } else if (this.selectedDevice === 'SensorDoorWindow') {
          status = 'Closed'
        } else if (this.selectedDevice === 'SensorSmoke') {
          status = 'No Smoke Detected'
        } else if (this.selectedDevice === 'SensorWaterLeak') {
          status = 'No Water Leak Detected'
        } else {
          status = 'No Status'
        }
        if (
          this.selectedDevice === 'SmartPlug' ||
        this.selectedDevice === 'WallSwitch' ||
        this.selectedDevice === 'SmartLightColorDimmable' ||
        this.selectedDevice === 'SmartLightColorChangeable'
        ) {
          tunable = true
        } else {
          tunable = false
        }
        if (
          this.selectedDevice === 'SensorMotion' ||
        this.selectedDevice === 'SensorDoorWindow' ||
        this.selectedDevice === 'SensorVibration'
        ) {
          security = true
        } else {
          security = false
        }
        if (this.selectedDevice === 'SensorSmoke' ||
        this.selectedDevice === 'SensorWaterLeak') {
          alarm = true
        } else {
          alarm = false
        }
        if (this.selectedDeviceTypeI === 'SensorTemperatureOutdoor' || this.selectedDeviceTypeI === 'SensorTemperatureIndoor') {
          temperatureSensor = true
        } else {
          temperatureSensor = false
        }
        this.$store.dispatch('addPropertyDevice', {
          id: null,
          propertyRoomId: this.selectedRoom,
          appUserIds: this.$store.state.userId + ',',
          zigbeeModel: this.selectedDevice,
          typeI: this.selectedDeviceTypeI,
          deviceName: this.deviceName.replace(/(^\w|\s\w)/g, m => m.toUpperCase()),
          tunable,
          security,
          alarm,
          battery: 0,
          temperatureSensor,
          status,
          ieee: null,
          active: false
        })
        this.selectedView++
        this.progressPlus(100)
      }
    },
    resetAddingDevice () {
      this.selectedView = 1
      this.progress = 0
      this.checkedAllDone = false
      this.$store.dispatch('resetAddingDeviceStatus')
    },
    closeKeyboard () {
      Keyboard.hide()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import '~@/theme/_layouts';
@import '~@/theme/_backgrounds';
  .device {
    height: 180px;
    @include bg-box-room-device;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.308);
  h3{
    margin: 0;
    margin-top:30px;
    font-size: 15px;
    letter-spacing: 0.6px;
    font-weight: normal;
  }
  }
  .selected {
    background: #3F424A;
    border: 1px solid colors(primary, base);
    h3 {
      color: colors(primary, base);
    }
  }
.buttonRoom {
  padding-left: 2px;
  padding-right: 2px;
}
.inactive {
    --background: rgba(44, 48, 54, 0.6);
    color: white;
    max-height: 44px;
}
.active {
    --background: #3F424A;
    color: colors(primary, base);
    max-height: 44px;
    border: 1px solid colors(primary, base);
    border-radius: 5px;
}

  .connectDevices {
    padding: 0 0px;
  }
  .connectDevices {
    .connecting-device {
      padding: 0px 0 10px;
    }
    img {
      max-width: 100px;
    }
    .connect-device-content {
      ion-item {
        --padding-start: 0;
        --inner-padding-end: 0;
        ion-label {
          white-space: normal;
          color: #fff;
          letter-spacing: 0.6px;
          font-size: 14px;
          background: transparent;
        }
      }
    }
  }
      ul {
      list-style: none;
      padding-left: 0;
      padding: 10px;
      li {
        color: #fff;
        text-align: left;
        letter-spacing: 0.6px;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 15px;
        display: flex;
      }
      li:before {
        content: "✓";
        color: #ffd4a4;
        padding-right: 10px;
        font-size: 18px;
      }
    }
.loader-wrapper {
  position: relative;
}
.back-circle-loader {
  position: relative;
  z-index: -1;
  height: 214px;
  width: 214px;
  background: linear-gradient(180deg, #ffd5a433 0%, #f4b87421 100%);
  // opacity: 0.1;
  border-radius: 50%;
  display: inline-block;
}
.loader-anim {
  position: relative;
  top: 7px;
  left: 7px;
  border: 8px solid #f3f3f300;
  border-top: 8px solid #ffd4a4;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  -webkit-animation: spinAnim 1s linear infinite;
  animation: spinAnim 1s linear infinite;
}
@keyframes spinAnim {
  from {
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spinAnim {
  from {
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}

.laoder-percentage {
  color: white;
  z-index: 4;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 52px;
  line-height: 61px;
}
.inner-loader {
  z-index: 3;
  position: absolute;
  top: 25px;
  left: 25px;
  // transform: translate(-50%, -50%);
  height: 164px;
  width: 164px;
  box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
  filter: drop-shadow(0px 7px 11px rgba(0, 0, 0, 0.15));
  backdrop-filter: blur(42px);
  border-radius: 50%;
}
.inner-loader-frame {
  position: absolute;
  z-index: 5;
  top: 25px;
  left: 25px;
  // transform: translate(-50%, -50%);
  height: 165px;
  width: 165px;
  border-radius: 50%;
  background: radial-gradient(100% 100% at 0% 0%, rgba(206, 206, 206, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
  border: 1px solid rgba(150, 150, 150, 0.205);
}
.keyboard-opened {
  img {
    transition: 0.4s;
    height: 12vh;
  }
}
.keyboard-closed {
  img {
    transition: 0.4s;
    height: 25vh;
  }
}
</style>
