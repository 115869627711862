<template>
  <ion-app>
    <ion-split-pane
      v-if="isUserLoggedIn"
      when="lg"
      content-id="main-content"
    >
      <ion-menu
        side="start"
        content-id="main-content"
        class="sidemenu"
      >
        <Menu />
      </ion-menu>
      <ion-router-outlet
        id="main-content"
        :swipe-gesture="false"
        main
        :class="userRole === 'Landlord' ? 'background--landlord' : 'background--default'"
      />
    </ion-split-pane>
    <ion-router-outlet
      v-else
      id="main-content"
      :swipe-gesture="false"
      main
      class="background--default"
    />
  </ion-app>
</template>

<script>
import { getPlatforms, IonApp, IonRouterOutlet, IonSplitPane, loadingController, useBackButton } from '@ionic/vue'
import { Keyboard } from '@capacitor/keyboard'
import { App } from '@capacitor/app'
import { StatusBar, Style } from '@capacitor/status-bar'
import { defineComponent } from 'vue'
import Menu from './components/SideMenu.vue'

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    IonSplitPane,
    Menu
  },
  data () {
    return {
      platform: [],
      loading: false
    }
  },
  computed: {
    isUserLoggedIn () {
      return this.$store.getters.loggedIn
    },
    isGatewayActive () {
      return this.$store.state.userProperties.length ? this.$store.state.userProperties[0].gatewayActive : undefined
    },
    isGatewayPresent () {
      return this.$store.state.userProperties.length ? this.$store.state.userProperties[0].propertyGateway : false
    },
    isUserActive () {
      return this.$store.state.userData.length ? this.$store.state.userData.active : undefined
    },
    latestLandlordsNotification () {
      return this.$store.state.latestLandlordsNotification
    },
    showLandlordsNotification () {
      return this.$store.state.showLandlordsNotification
    },
    landlordBuildings () {
      return this.$store.state.landlordBuildings
    },
    landlordProperties () {
      return this.$store.state.landlordProperties
    },
    userRole () {
      return this.$store.state.userRole
    }
  },
  watch: {
    // isGatewayActive (value) {
    //   if (value === false && this.isGatewayPresent) {
    //     this.presentLoading()
    //     this.loading = true
    //   } else if (value === true && this.loading === true) {
    //     this.dismissLoading()
    //     this.loading = false
    //   }
    // },
    isUserActive (value) {
      if (!value) {
        this.presentWarning()
      } else {
        this.dismissLoading()
      }
    },
    latestLandlordsNotification: function (newValue, oldValue) {
      if (newValue) { this.openToast() }
    }
  },
  created () {
    const platform = getPlatforms()
    this.platform = platform
    this.$store.commit('platform', platform)

    if (this.platform.includes('capacitor')) {
      StatusBar.setStyle({ style: Style.Dark })

      Keyboard.addListener('keyboardDidShow', info => {
        // console.log(info)
        this.$store.commit('keyboardEvent', 'open')
      })
      Keyboard.addListener('keyboardDidHide', () => {
        this.$store.commit('keyboardEvent', 'closed')
      })
    }
    const userRole = this.$store.state.userRole
    if (userRole === 'Landlord') {
      this.$store.dispatch('getLandlordBuildingsAndProperties')
    }
    App.addListener('appStateChange', ({ isActive }) => {
      // console.log('App state changed. Is active?-----------------', isActive)
      if (isActive) {
        const userRole = this.$store.state.userRole
        if (userRole !== 'Landlord' && this.$store.getters.loggedIn) {
          this.$store.dispatch('getUserData')
        } else if (userRole === 'Landlord' && this.$store.getters.loggedIn) {
          // console.log('gettin landlords data')
          this.$store.dispatch('getLandlordBuildingsAndProperties')
        }
        if (this.$store.getters.loggedIn) {
          this.$store.dispatch('connectSocketIo')
        }
      }
    })
    if (this.$store.getters.loggedIn) {
      this.$store.dispatch('connectSocketIo')
    }
    useBackButton(9999, () => {
      this.dismiss()
    })
  },
  mounted () {
  },
  methods: {
    async presentLoading () {
      const loading = await loadingController
        .create({
          cssClass: 'gateway-disconnected-overlay',
          message: `${this.$t('Gateway seems to be disconnected')} <br><br><a href="/help-with-gateway">${this.$t('Get Help')}</a>`,
          spinner: 'crescent'
        })

      await loading.present()
    },
    dismissLoading () {
      loadingController.dismiss()
    },
    async presentWarning () {
      const loading = await loadingController
        .create({
          cssClass: 'gateway-disconnected-overlay',
          message: 'Your account has been locked...'
        })

      await loading.present()
    },
    openToast () {
      const nots = this.latestLandlordsNotification
      const property = this.landlordProperties.filter(propertya => propertya.id === nots.propertyId)
      console.log(this.landlordBuildings)
      const buildingName = this.landlordBuildings.filter(item => item.id === property[0].buildingId)
      console.log(buildingName)
      console.log(nots)
      this.$toast.show(`<i class="mdi mdi-close-circle" style="margin-left: -27px; margin-top: -8px;"></i><a class="toast-notification" style="color: white; margin-top: -18px;" href="/detail/${nots.propertyId}">${buildingName[0].buildingName} / ${property[0].propertyName} >>>> ${nots.warningMessage} detected!</a>`, {
        type: 'error'
        // onClick: () => this.redirect(nots.propertyId)
      })
    }
    // redirect (propertyId) {
    //   this.$router.push(`/detail/${propertyId}`)
    // }
  }
})
</script>

<style lang="scss" scoped>
ion-split-pane {
  --side-max-width: 150px;
  --border: none;
}
a:hover {
  color: black;
}
</style>