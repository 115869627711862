<template>
  <ion-header
    class="header-wrapper no-shadow"
  >
    <ion-toolbar>
      <ion-title>
        <ion-item
          button
          lines="none"
          detail="false"
          class="header-item"
          @click="editMyProfile('EditMyProfileModal'); closeMenu();"
        >
          <ion-avatar v-if="$store.state.userRole !== 'Landlord'">
            <ion-img
              v-if="profileImage"
              class="profileImage"
              :src="profileImage"
            />
            <ion-img
              v-else
              class="profileImage"
              :src="require('../assets/images/default.png')"
            />
          </ion-avatar>
          <ion-label class="header-label">
            {{ getUserName }}
          </ion-label>
          <ion-icon :icon="chevronForwardOutline" />
        </ion-item>
      </ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <ion-list>
      <ion-item
        v-if="$store.state.userRole === 'Landlord'"
        lines="none"
        button
        detail="false"
        class="add-device pt-15"
        @click="$router.push('/add-property'); closeMenu();"
      >
        <ion-icon
          color="primary"
          :icon="add"
        />
        <ion-label color="primary">
          <!-- {{ $t('addProperty') }} -->
          Add Property
        </ion-label>
      </ion-item>
      <ion-item
        v-if="$store.state.userRole === 'Landlord'"
        lines="none"
        button
        detail="false"
        @click="$router.push('/main-view'); closeMenu();"
      >
        <ion-icon
          :icon="homeOutline"
        />
        <ion-label>
          <!-- {{ $t('overview') }} -->
          Overview
        </ion-label>
      </ion-item>
      <ion-item
        v-if="$store.state.userRole === 'Landlord'"
        lines="none"
        button
        detail="false"
        class="add-device"
        @click="$router.push('/landlord-properties'); closeMenu();"
      >
        <ion-icon
          :icon="businessOutline"
        />
        <ion-label>
          <!-- {{ $t('manageProperties') }} -->
          Manage Properties
        </ion-label>
      </ion-item>
      <ion-item
        v-if="$store.state.userRole !== 'Landlord'"
        lines="none"
        button
        detail="false"
        style="margin-top: 15px;"
        class="add-device"
        @click="addDevices(); closeMenu();"
      >
        <ion-icon
          color="primary"
          :icon="add"
        />
        <ion-label color="primary">
          {{ $t('addDevice') }}
        </ion-label>
      </ion-item>
      <ion-item
        v-if="$store.state.userRole !== 'Landlord'"
        button
        detail="false"
        lines="none"
        @click="openMyDevices(); closeMenu();"
      >
        <ion-icon :icon="grid" />
        <ion-label>{{ $t('myDevices') }}</ion-label>
      </ion-item>
      <ion-item
        v-if="$store.state.userRole !== 'Landlord'"
        lines="none"
        detail="false"
        button
        disabled
      >
        <ion-icon :icon="home" />
        <ion-label>{{ $t('manageProperties') }}</ion-label>
      </ion-item>
      <ion-item
        v-if="$store.state.userRole !== 'Landlord'"
        lines="none"
        button
        :disabled="$store.state.userRole === 'House Member'"
        detail="false"
        @click="openHomeMembersModal(); closeMenu();"
      >
        <ion-icon :icon="people" />
        <ion-label>{{ $t('members') }}</ion-label>
      </ion-item>
      <ion-item
        v-if="$store.state.userRole !== 'Landlord'"
        lines="none"
        button
        :disabled="$store.state.userRole === 'House Member'"
        detail="false"
        @click="openMyOrdersModal(); closeMenu();"
      >
        <ion-icon :icon="cardOutline" />
        <ion-label>{{ $t('Membership') }}</ion-label>
      </ion-item>
    </ion-list>
    <!-- <ion-list>
      <ion-item
        lines="none"
        detail="false"
        style="margin-top: 15px;"
        button
        @click="openFeedbackModal();closeMenu();"
      >
        <ion-icon :icon="thumbsUp" />
        <ion-label>{{ $t('feedback') }}</ion-label>
      </ion-item>
      <ion-item
        detail="false"
        style="margin-top: 15px;"
        button
        lines="none"
        @click="$router.push('/help-center');closeMenu();"
      >
        <ion-icon :icon="informationCircle" />
        <ion-label>{{ $t('helpCenter') }}</ion-label>
      </ion-item>
    </ion-list> -->
    <ion-list>
      <ion-item
        detail="false"
        style="margin-top: 15px;"
        button
        lines="none"
        @click="$router.push('/help-center');closeMenu();"
      >
        <ion-icon :icon="informationCircle" />
        <ion-label>{{ $t('helpCenter') }}</ion-label>
      </ion-item>
      <ion-item
        lines="none"
        detail="false"
        button
        @click="openSettingsModal();closeMenu();"
      >
        <ion-icon :icon="options" />
        <ion-label>{{ $t('settings') }}</ion-label>
      </ion-item>
    </ion-list>
    <ion-list>
      <ion-item
        lines="none"
        style="margin-top: 15px;"
        detail="false"
        button
        @click="LogOut"
      >
        <ion-icon :icon="power" />
        <ion-label>{{ $t('logout') }}</ion-label>
      </ion-item>
    </ion-list>
    <div class="center-absolute">
      <span class="brand-name">Homevision</span><br>
      <span class="version">v.{{ appVersion }}</span>
    </div>
  </ion-content>
</template>

<script>
import myDevicesModal from '../components/MyDevicesModal'
import addDevicesModal from '../components/AddDeviceModal'
import editMyProfileModal from '../components/EditMyProfileModal'
import settingsModal from '../components/SettingsModal.vue'
import homeMembersModal from './HomeMembers/HomeMembersModal.vue'
import feedbackModal from './FeedbackModal.vue'
import myOrdersModal from './Eshop/MyOrdersAndSubsModal.vue'

import { modalController, menuController } from '@ionic/vue'
// import { Geofence } from '@ionic-native/geofence/'
import BackgroundGeolocation from '@transistorsoft/capacitor-background-geolocation'
// import { Plugins } from '@capacitor/core'
import { PushNotifications } from '@capacitor/push-notifications'

import { getAppVersion } from '../lib/variables'

import {
  chevronForwardOutline,
  notifications,
  add,
  home,
  people,
  thumbsUp,
  informationCircle,
  options,
  power,
  grid,
  homeOutline,
  businessOutline,
  cardOutline
} from 'ionicons/icons'
// const { PushNotifications, Keyboard } = Plugins

export default {
  props: {
    devices: { type: Array, default: () => [] },
    close: { type: Function, default: null }
  },
  data () {
    return {
      chevronForwardOutline,
      notifications,
      add,
      home,
      people,
      thumbsUp,
      informationCircle,
      options,
      power,
      grid,
      homeOutline,
      businessOutline,
      cardOutline,
      appVersion: getAppVersion()
    }
  },
  computed: {
    profileImage () {
      return this.$store.state.profileImage
    },
    getUserName () {
      return this.$store.state.userName
    },
    platform () {
      return this.$store.state.platform
    }
  },
  methods: {
    LogOut () {
      console.log('logout')
      if (this.platform.includes('capacitor')) {
        PushNotifications.removeAllListeners()
        BackgroundGeolocation.removeGeofences().then(success => {
          BackgroundGeolocation.stop()
          this.$store.dispatch('removeGeofence')
        })
      }
      this.$store.dispatch('LogOut').then(() => {
        this.$router.replace({ path: 'signup' })
      })
    },
    editMyProfile (whichModal) {
      let modal
      if (whichModal === 'EditMyProfileModal') {
        modal = editMyProfileModal
      }
      return modalController
        .create({
          component: modal,
          cssClass: 'my-profile-modal',
          swipeToClose: true,
          showBackdrop: true,
          backdropDismiss: true,
          componentProps: {
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    openMyDevices () {
      return modalController
        .create({
          component: myDevicesModal,
          cssClass: 'devices-modal',
          swipeToClose: true,
          showBackdrop: true,
          backdropDismiss: true,
          componentProps: {
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    addDevices () {
      return modalController
        .create({
          component: addDevicesModal,
          cssClass: 'devices-modal',
          swipeToClose: true,
          showBackdrop: true,
          backdropDismiss: true,
          componentProps: {
            roomId: null,
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    openFeedbackModal () {
      return modalController
        .create({
          component: feedbackModal,
          cssClass: 'devices-modal',
          swipeToClose: true,
          showBackdrop: true,
          backdropDismiss: true,
          componentProps: {
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    openSettingsModal () {
      return modalController
        .create({
          component: settingsModal,
          cssClass: 'devices-modal',
          swipeToClose: true,
          showBackdrop: true,
          backdropDismiss: true,
          componentProps: {
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    openHomeMembersModal () {
      return modalController
        .create({
          component: homeMembersModal,
          cssClass: 'devices-modal',
          swipeToClose: true,
          showBackdrop: true,
          backdropDismiss: true,
          componentProps: {
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    openMyOrdersModal () {
      return modalController
        .create({
          component: myOrdersModal,
          cssClass: 'devices-modal',
          swipeToClose: true,
          showBackdrop: true,
          backdropDismiss: true,
          componentProps: {
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    closeModal () {
      modalController.dismiss()
    },
    closeMenu () {
      menuController.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.sidemenu {
  height: 100%;
  width: 100%;
  --max-width: 70%;
  --background: #262C39;
  ion-menu {
    --background: #262C39 !important;
  }
  ion-header {
    ion-toolbar {
      padding: 10px 0;
      --border-style: transparent !important;
      ion-title {
        font-weight: 500;
        letter-spacing: 0.6px;
      }
    }
  }
  ion-header,
  ion-toolbar,
  ion-content,
  ion-list,
  ion-item,
  ion-icon,
  ion-title {
    --background: #262C39;
    color: #fff;
    margin: 0;
    padding: 0;
    border: none;
  }
  ion-item {
    --border-color: #e5e5e5;
    padding-bottom: 10px;
    --color-hover: #FFD4A4;
  }
  ion-label {
    margin-left: 20px;
    font-size: 16px;
    letter-spacing: 0.6px;
    font-weight: 400;
  }

  ion-icon {
    font-size: 24px;
    color: #d9e1d981;
  }
  ion-list {
    border-top: 1px solid rgba(194, 194, 194, 0.1);
    padding-top: 15px;
    margin: 4px auto;
  }
  ion-avatar {
    width: 42px;
    height: 42px;
    border: 2px solid #FFD4A4;
    border-radius: 50%;
  }
  .header-item {
    margin-top: 10px;
  }
  .header-label {
    margin-left: 10px;
  }

  .side-menu-image {
    width: 15%;
    display: inline-block;
  }

  .username {
    margin-left: 25px;
  }
  .center-absolute {
    position: absolute;
    bottom: 35px;
    left: 50%;
  }

  .brand-name {
    position: relative;
    left: -50%;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.6px;
    color: rgba(255, 255, 255, 0.2);
  }
  .version {
    position: relative;
    left: -50%;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.6px;
    color: rgba(255, 255, 255, 0.2);
  }
}
</style>
